import React from "react";

import "./ConfirmationModal.scss";

export const ConfirmationModal = ({
  toggleModal, // a function that toggles the isShown bool of the modal
  headerText,
  subheadingText,
  buttonText,
  onClick,
}) => {
  return (
    <div className="leave-team-modal-window">
      <div className="topSection">
        <div className="headerText">{headerText}</div>
        <div className="subtext">{subheadingText}</div>
        <div className="closeButton" onClick={toggleModal}>
          <svg
            width="46"
            height="45"
            viewBox="0 0 46 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8506 33.4481L34.1494 11.1493"
              stroke="#8877DC"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M11.8506 11.1495L34.1494 33.4484"
              stroke="#8877DC"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="button leave-button center" onClick={onClick}>
          {buttonText}
        </div>
      </div>
    </div>
  );
};
