import React from "react";
import "./Sponsors.scss";

const Sponsors = (props) => {
  return (
    <div>
      {props.data.map((item) => {
        return (
          <div className="sponsor-container">
            <div className="terabyte-container sponsor-card">
              <div className="sponsor-heading">Terabyte Sponsors:</div>
              {item.sponsors.terabyte.map((sponsor) => {
                return (
                  <a href={sponsor.website} target="blank" className="terabyte">
                    <img
                      className="terabyte"
                      src={sponsor.logo}
                      alt={sponsor.name}
                      id={sponsor.name}
                    />
                  </a>
                );
              })}
            </div>
            {item.sponsors.gigabyte.length > 0 && (
              <div className="gigabyte-container sponsor-card">
                <div className="sponsor-heading">Gigabyte Sponsors:</div>
                {item.sponsors.gigabyte.map((sponsor) => {
                  return (
                    <a
                      href={sponsor.website}
                      target="blank"
                      className="gigabyte"
                    >
                      <img
                        className="gigabyte"
                        src={sponsor.logo}
                        alt={sponsor.name}
                        id={sponsor.name}
                      />
                    </a>
                  );
                })}
              </div>
            )}
            <div className="megabyte-container sponsor-card">
              <div className="sponsor-heading">Megabyte Sponsors:</div>
              <div>
                {item.sponsors.megabyte.map((sponsor) => {
                  return (
                    <a
                      href={sponsor.website}
                      target="blank"
                      className="megabyte"
                    >
                      <img
                        className="megabyte"
                        src={sponsor.logo}
                        alt={sponsor.name}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="supporter-container sponsor-card">
              <div className="sponsor-heading">Our Supporters:</div>
              <div>
                {item.sponsors.supporter.map((sponsor) => {
                  return (
                    <a
                      href={sponsor.website}
                      target="blank"
                      className="supporter"
                    >
                      <img
                        className="supporter"
                        src={sponsor.logo}
                        alt={sponsor.name}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Sponsors;
