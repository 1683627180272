import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import "./TeamCheckIn.scss";

const TeamCheckIn = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  const teamID = currentUser.teamID;
  const [teamData, setTeamData] = useState({
    name: "",
    projectName: "",
    devpost: "",
    member1: "",
    member2: "",
    member3: "",
    member4: "",
  });

  const [checkedChallenges, setCheckedChallenges] = useState([]);

  const [matroxChecked, setMatroxChecked] = useState(false);
  const [loadboardChecked, setLoadboardChecked] = useState(false);
  useEffect(() => {
    if (!currentUser.teamID) {
      window.location.href = "/dashboard/editTeam/create";
    } else {
      let newTeamData;
      axios
        .get(apiUrl + "teams/" + currentUser.teamID)
        .then((teamRes) => {
          console.log(teamRes);
          let teamResData = teamRes.data.data;
          newTeamData = {
            ...teamData,
            name: teamResData.name,
            devpost: teamResData.devpost,
            projectName: teamResData.projectName,
          };
          setCheckedChallenges(
            teamResData.checkedInSponsoredChallenges.split(","),
          );
          if (teamResData.checkedInSponsoredChallenges.includes("Matrox")) {
            setMatroxChecked(true);
          }
          if (
            teamResData.checkedInSponsoredChallenges.includes("123Loadboard")
          ) {
            setLoadboardChecked(true);
          }
        })
        .then(() => {
          axios
            .get(apiUrl + "teams/teamMembers/" + currentUser.teamID)
            .then((teamMemberRes) => {
              console.log(teamMemberRes);
              let teamMemberResData = teamMemberRes.data.data;
              newTeamData = {
                ...newTeamData,
                member1:
                  teamMemberResData[0].firstName +
                  " " +
                  teamMemberResData[0].lastName,
              };
              if (teamMemberResData[1]) {
                newTeamData = {
                  ...newTeamData,
                  member2:
                    teamMemberResData[1].firstName +
                    " " +
                    teamMemberResData[1].lastName,
                };
              }
              if (teamMemberResData[2]) {
                newTeamData = {
                  ...newTeamData,
                  member3:
                    teamMemberResData[2].firstName +
                    " " +
                    teamMemberResData[2].lastName,
                };
              }
              if (teamMemberResData[3]) {
                newTeamData = {
                  ...newTeamData,
                  member4:
                    teamMemberResData[3].firstName +
                    " " +
                    teamMemberResData[3].lastName,
                };
              }
              setTeamData(newTeamData);
            });
        });
    }
  }, []);

  const handleCheckboxChange = (e) => {
    e.target.value === "Matrox"
      ? setMatroxChecked(!matroxChecked)
      : setLoadboardChecked(!loadboardChecked);

    if (e.target.value === "Matrox" && e.target.checked) {
      setCheckedChallenges([...checkedChallenges, "Matrox"]);
    } else if (e.target.value === "123Loadboard" && e.target.checked) {
      setCheckedChallenges([...checkedChallenges, "123Loadboard"]);
    } else {
      setCheckedChallenges(
        checkedChallenges.filter((c) => c !== e.target.value),
      );
    }
  };

  const onSubmit = () => {
    if (teamData.projectName.length < 1) {
      setErrorMessage("Project name must be at least 1 character long");
      return;
    } else if (teamData.projectName.length > 50) {
      setErrorMessage("Project name must be less than 50 characters long");
      return;
    } else if (
      teamData.devpost &&
      !teamData.devpost.toLowerCase().includes("devpost.com")
    ) {
      setErrorMessage("Invalid Devpost link");
      return;
    }

    let membersString = [
      teamData.member1,
      teamData.member2,
      teamData.member3,
      teamData.member4,
    ]
      .filter((c) => c !== "")
      .join(",");

    let requestData = {
      name: teamData.name,
      projectName: teamData.projectName,
      devpost: teamData.devpost,
      members: membersString,
      checkedInSponsoredChallenges: checkedChallenges.toString(),
      teamID: teamID,
    };

    const headers = {
      authorization: token,
    };

    axios
      .put(apiUrl + "teams/checkIn", requestData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.message.includes("Team checked-in")) {
          window.location.href = "/dashboard/team/" + teamID;
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <div className="checkin-page">
        <h1>Team Check-In</h1>
        
      </div>
    </>
  );
};

export default TeamCheckIn;
