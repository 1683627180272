import loadboard13 from "../Resources/CodeJam13Sponsors/123Loadboard.png";
import password13 from "../Resources/CodeJam13Sponsors/1Password.png";
import plotly13 from "../Resources/CodeJam13Sponsors/Plotly.png";
import serviceNow from "../Resources/CodeJam13Sponsors/servicenow.png";
import mdaSpace from "../Resources/CodeJam13Sponsors/mdaspace.png";
import stickerMule from "../Resources/CodeJam13Sponsors/stickermule.png";

import LoadBoard from "../Resources/CodeJam12Sponsors/123Loadboard.png";
import HoYoVerse from "../Resources/CodeJam12Sponsors/hoyoverse.png";
import matrox from "../Resources/CodeJam12Sponsors/matrox.png";
import servicenow from "../Resources/CodeJam12Sponsors/servicenow.png";
import password from "../Resources/CodeJam12Sponsors/1Password.png";
import mistplay from "../Resources/CodeJam12Sponsors/mistplay.png";
import morganstanley from "../Resources/CodeJam12Sponsors/morganstanley.png";
import Giro from "../Resources/CodeJam12Sponsors/giro.png";
import Guru from "../Resources/CodeJam12Sponsors/guru.png";
import CN_logo from "../Resources/CodeJam12Sponsors/CN_Logo.png";
import Student_Support from "../Resources/CodeJam12Sponsors/Student_Support.png";
import matrox_white from "../Resources/CodeJam12Sponsors/matrox_white.png";

import ecsess from "../Resources/CodeJam12Sponsors/ecsess.png";

import broadsign from "../Resources/CodeJam11Sponsors/broadsign.png";
import Mihoyo from "../Resources/CodeJam11Sponsors/mihoyo.png";
import Drakkar from "../Resources/CodeJam11Sponsors/drakkar.png";
import FDM from "../Resources/CodeJam11Sponsors/FDM.svg";
import CapitalOne from "../Resources/CodeJam11Sponsors/CapitalOne.svg";
import MayaHTT from "../Resources/CodeJam11Sponsors/MayaHTT.svg";
import GatherTown from "../Resources/CodeJam11Sponsors/gather.png";

import samasource from "../Resources/CodeJam10Sponsors/samasource.svg";
import balsamiq from "../Resources/CodeJam10Sponsors/Balsamiq.svg";
import CDS from "../Resources/CodeJam10Sponsors/CDS.png";
import Ubisoft from "../Resources/CodeJam10Sponsors/Ubisoft.svg";
import Coveo from "../Resources/CodeJam10Sponsors/coveo.png";
import SAP from "../Resources/CodeJam10Sponsors/SAP.png";
import Wolfram from "../Resources/CodeJam10Sponsors/Wolfram.svg";
import MLH from "../Resources/CodeJam10Sponsors/MLH.svg";
import echoar from "../Resources/CodeJam10Sponsors/echoar.png";

import Wrnch from "../Resources/CodeJam09Sponsors/Wrnch.svg";
import Intact from "../Resources/CodeJam09Sponsors/Intact.svg";
import plotly from "../Resources/CodeJam09Sponsors/plotly.png";
import Expedia from "../Resources/CodeJam09Sponsors/Expedia.svg";
import Explorance from "../Resources/CodeJam09Sponsors/Explorance.svg";
import Genetec from "../Resources/CodeJam09Sponsors/genetec.png";
import Github from "../Resources/CodeJam09Sponsors/Github.svg";
import hopper from "../Resources/CodeJam09Sponsors/hopper.svg";
import IndexExchange from "../Resources/CodeJam09Sponsors/indexexchange.png";
import SSENSE from "../Resources/CodeJam09Sponsors/SSENSE.svg";
import Ericsson from "../Resources/CodeJam09Sponsors/Ericsson.svg";
import Kronos from "../Resources/CodeJam09Sponsors/Kronos.svg";
import PWC from "../Resources/CodeJam09Sponsors/PWC.svg";
import Squarepoint from "../Resources/CodeJam09Sponsors/Squarepoint.svg";

const SponsorData = [
  {
    id: "codejam13",
    sponsors: {
      terabyte: [
        {
          name: "123LoadBoard",
          logo: loadboard13,
          website: "https://www.123loadboard.com",
        },
        {
          name: "matrox",
          logo: matrox_white,
          website: "https://www.matrox.com/en",
        },
      ],
      gigabyte: [
        {},
        {
          name: "1Password",
          logo: password13,
          website: "https://www.1password.com",
        },
        {
          name: "ServiceNow",
          logo: serviceNow,
          website: "https://www.servicenow.com/",
        },
        {},
      ],
      megabyte: [
        {
          name: "Plotly",
          logo: plotly13,
          website: "https://plotly.com/",
        },
        {
          name: "MorganStanley",
          logo: morganstanley,
          website: "https://www.morganstanley.com",
        },
        {
          name: "MDASpace",
          logo: mdaSpace,
          website: "https://mda.space/en/",
        },
      ],
      supporter: [
        {
          name: "StickerMule",
          logo: stickerMule,
          website: "https://mule.to/p5ar",
        },
      ],
    },
  },

  {
    id: "codejam12",
    sponsors: {
      terabyte: [
        {
          name: "123LoadBoard",
          logo: LoadBoard,
          website: "https://www.123loadboard.com",
        },
        {
          name: "HoYoVerse",
          logo: HoYoVerse,
          website: "https://www.hoyoverse.com/en-us/",
        },
        {
          name: "matrox",
          logo: matrox_white,
          website: "https://www.matrox.com/en",
        },
        {
          name: "servicenow",
          logo: servicenow,
          website: "https://www.servicenow.com",
        },
      ],
      gigabyte: [
        {
          name: "1Password",
          logo: password,
          website: "https://www.1password.com",
        },
        {
          name: "CN",
          logo: CN_logo,
          website: "https://www.cn.ca/en/",
        },
        {
          name: "Mistplay",
          logo: mistplay,
          website: "https://www.mistplay.com",
        },
        {
          name: "MorganStanley",
          logo: morganstanley,
          website: "https://www.morganstanley.com",
        },
      ],
      megabyte: [{ name: "Giro", logo: Giro, website: "https://www.giro.ca" }],
      supporter: [
        {
          name: "Guru",
          logo: Guru,
          website: "https://www.guruenergy.com",
        },
        {
          name: "Student_Support",
          logo: Student_Support,
          website: "https://www.studentsupport.ca/",
        },
        {
          name: "ECSESS",
          logo: ecsess,
          website: "https://ecsess.mcgilleus.ca",
        },
      ],
    },
  },
  {
    id: "codejam11",
    sponsors: {
      terabyte: [
        {
          name: "123LoadBoard",
          logo: LoadBoard,
          website: "https://www.123loadboard.com/",
        },
        {
          name: "Broadsign",
          logo: broadsign,
          website: "https://broadsign.com/",
        },
        {
          name: "MiHoYo",
          logo: Mihoyo,
          website: "https://www.mihoyo.com/en/",
        },
      ],
      gigabyte: [],
      megabyte: [
        {
          name: "Drakkar Capital",
          logo: Drakkar,
          website: "https://www.drakkarcapital.com/",
        },
        {
          name: "Giro",
          logo: Giro,
          website: "https://www.giro.ca/",
        },
        {
          name: "FDM",
          logo: FDM,
          website: "https://www.fdmgroup.com/",
        },
        {
          name: "Capital One",
          logo: CapitalOne,
          website: "https://www.capitalone.ca/",
        },
        {
          name: "MayaHTT",
          logo: MayaHTT,
          website: "https://www.mayahtt.com/",
        },
      ],
      supporter: [
        {
          name: "GatherTown",
          logo: GatherTown,
          website: "https://www.gather.town/",
        },
      ],
    },
  },
  {
    id: "codejam10",
    sponsors: {
      terabyte: [
        {
          name: "Matrox",
          logo: matrox,
          website: "https://www.matrox.com/en",
        },
        {
          name: "Samasource",
          logo: samasource,
          website: "https://www.sama.com/",
        },
      ],
      gigabyte: [
        {
          name: "MorganStanley",
          logo: morganstanley,
          website: "https://www.morganstanley.com/",
        },
      ],
      megabyte: [
        {
          name: "FDM Group",
          logo: FDM,
          website: "https://www.fdmgroup.com/",
        },
        {
          name: "Balsamiq",
          logo: balsamiq,
          website: "https://balsamiq.com/",
        },
        {
          name: "Cirque du Soleil",
          logo: CDS,
          website: "https://www.cirquedusoleil.com/",
        },
        {
          name: "Ubisoft",
          logo: Ubisoft,
          website: "https://www.ubisoft.com/",
        },
        {
          name: "Coveo",
          logo: Coveo,
          website: "https://www.coveo.com/en",
        },
        {
          name: "SAP",
          logo: SAP,
          website: "https://www.sap.com/",
        },
      ],
      supporter: [
        {
          name: "Wolfram",
          logo: Wolfram,
          website: "https://www.wolfram.com/",
        },
        {
          name: "GatherTown",
          logo: GatherTown,
          website: "https://www.gather.town/",
        },
        {
          name: "MLH",
          logo: MLH,
          website: "https://mlh.io/",
        },
        {
          name: "EchoAR",
          logo: echoar,
          website: "https://experienceechoar.com/",
        },
      ],
    },
  },
  {
    id: "codejam09",
    sponsors: {
      terabyte: [
        {
          name: "wrnch",
          logo: Wrnch,
          website: "https://www.linkedin.com/company/wrnch/",
        },
        {
          name: "Samasource",
          logo: samasource,
          website: "https://www.sama.com/",
        },
        {
          name: "Intact",
          logo: Intact,
          website: "https://www.intact.ca/",
        },
      ],
      gigabyte: [
        {
          name: "plotly",
          logo: plotly,
          website: "https://plotly.com/",
        },
        {
          name: "mistplay",
          logo: mistplay,
          website: "https://www.mistplay.com/",
        },
      ],
      megabyte: [
        {
          name: "Cirque du Soleil",
          logo: CDS,
          website: "https://www.cirquedusoleil.com/",
        },
        {
          name: "Coveo",
          logo: Coveo,
          website: "https://www.coveo.com/",
        },
        {
          name: "Expedia",
          logo: Expedia,
          website: "https://www.expedia.ca/",
        },
        {
          name: "Explorance",
          logo: Explorance,
          website: "https://explorance.com/",
        },
        {
          name: "Genetec",
          logo: Genetec,
          website: "https://www.genetec.com/",
        },
        {
          name: "Giro",
          logo: Giro,
          website: "https://www.giro.ca/",
        },
        {
          name: "GitHub",
          logo: Github,
          website: "https://github.com/",
        },
        {
          name: "Hopper",
          logo: hopper,
          website: "https://hopper.com/",
        },
        {
          name: "Index Exchange",
          logo: IndexExchange,
          website: "https://www.indexexchange.com/",
        },
        {
          name: "SSENSE",
          logo: SSENSE,
          website: "https://www.ssense.com/",
        },
        {
          name: "Morgan Stanley",
          logo: morganstanley,
          website: "https://www.morganstanley.com/",
        },
        {
          name: "SAP",
          logo: SAP,
          website: "https://www.sap.com/",
        },
      ],
      supporter: [
        {
          name: "Ericsson",
          logo: Ericsson,
          website: "https://www.ericsson.com/",
        },
        {
          name: "Kronos",
          logo: Kronos,
          website: "https://www.ukg.ca/",
        },
        {
          name: "pwc",
          logo: PWC,
          website: "https://www.pwc.com/ca/en/",
        },
        {
          name: "Square Point",
          logo: Squarepoint,
          website: "https://www.squarepoint-capital.com/",
        },
        {
          name: "Wolfram",
          logo: Wolfram,
          website: "https://www.wolfram.com/",
        },
        {
          name: "Ubisoft",
          logo: Ubisoft,
          website: "https://www.ubisoft.com/",
        },
      ],
    },
  },
];

export default SponsorData;
