import React from "react";
import "./InfoCard.scss";

import ButtonCustom from "../Button/ButtonCustom.jsx";

function InfoCard(props) {
  return (
    <div className="infocard-container">
      <div className="text">
        <p className="edition">{props.edition}</p>
        <p className="codejam">{props.codejam}</p>
        <p className="date">{props.date}</p>
        <ButtonCustom onClick={props.onClick} title="View Projects" />
      </div>
    </div>
  );
}

export default InfoCard;
