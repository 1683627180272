import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Schedule from "./Schedule/Schedule";
import DashboardSidebar from "./DashboardSidebar/DashboardSidebar";
import Login from "../Website/Login/Login";
import Signup from "../Website/Signup/Signup";
import Team from "./Team/Team";
import TeamBrowser from "./TeamBrowser/TeamBrowser";
import EditTeam from "./EditTeam/EditTeam";
import ErrorModal from "./Components/ErrorModal/ErrorModal";
import "./Dashboard.scss";
import TeamCheckIn from "./TeamCheckIn/TeamCheckIn";
import axios from "axios";
import Profile from "./Profile/Profile";
import EditProfile from "./Profile/EditProfile";
import Withdraw from "./Withdraw/Withdraw";

const Dashboard = ({ apiUrl, token }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    document.body.style.background =
      "linear-gradient(to right, #F7F9FC 0%, #EBF2FF 100%)";

    if (token) {
      axios
        .get(apiUrl + "currentUser/", {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setCurrentUser(res.data.user);
        })
        .catch((err) => {
          // invalid token so go to login
          localStorage.removeItem("token");
          window.open(window.location.origin + "/login");
        });
    } else {
      localStorage.removeItem("token");
      window.open(window.location.origin + "/login");
    }

    // Don't forget to clean up the effect when the component unmounts
    return () => {
      document.body.style.background = "initial"; // Reset to the default background
    };
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  return currentUser && currentUser.accepted ? (
    <div>
      {errorMessage && (
        <ErrorModal
          toggleModal={() => {
            setErrorMessage("");
          }}
          errorMessage={errorMessage}
        />
      )}
      {/* Render your dashboard-specific components */}
      <DashboardSidebar currentUser={currentUser} apiUrl={apiUrl} />
      {/* Add any other components or routes specific to the dashboard */}
      <div
        className="dashboard-content"
        style={{
          ...(errorMessage ? { filter: "blur(5px)" } : null),
        }}
      >
        <Routes>
          <Route
            path="/profile"
            element={
              <Profile
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/profile/:id"
            element={
              <Profile
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/editProfile"
            element={
              <EditProfile
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/team"
            element={
              <Team
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/team/:id"
            element={
              <Team
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/editTeam/"
            element={
              <EditTeam
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/editTeam/:id"
            element={
              <EditTeam
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/checkIn"
            element={
              <TeamCheckIn
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/withdraw"
            element={
              <Withdraw
                apiUrl={apiUrl}
                currentUser={currentUser}
                setErrorMessage={setErrorMessage}
                token={token}
              />
            }
          />
          <Route
            path="/team-browser"
            element={<TeamBrowser apiUrl={apiUrl} />}
          />
        </Routes>
      </div>
    </div>
  ) : (
    <a
      onClick={() => {
        localStorage.removeItem("token");
        window.open(window.location.origin + "/login");
      }}
    >
      logout
    </a>
  );
};

export default Dashboard;
