import React, { useState } from "react";

import "./AddMemberModal.scss";

export const AddMemberModal = ({
  toggleModal, // a function that toggles the isShown bool of the modal
  uniqueJoinCode,
}) => {
  const [codeCopied, setCodeCopied] = useState(false);
  return (
    <div className="add-member-modal-window">
      <div className="topSection">
        <div className="headerText">Add team members</div>
        <div className="subtext">
          Share this team code for other members to join
        </div>
        <div className="closeButton" onClick={toggleModal}>
          <svg
            width="46"
            height="45"
            viewBox="0 0 46 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8506 33.4481L34.1494 11.1493"
              stroke="#8877DC"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M11.8506 11.1495L34.1494 33.4484"
              stroke="#8877DC"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      <div
        className="code-box"
        onClick={() => {
          navigator.clipboard.writeText(uniqueJoinCode);
          setCodeCopied(true);
        }}
      >
        <div>{uniqueJoinCode}</div>
        {codeCopied ? (
          <div className="copied-text">Copied!</div>
        ) : (
          <svg
            width="40"
            height="48"
            viewBox="0 0 40 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.3337 14V12C23.3337 10.1144 23.3337 9.17157 22.7479 8.58579C22.1621 8 21.2193 8 19.3337 8H10.667C8.78137 8 7.83857 8 7.25278 8.58579C6.66699 9.17157 6.66699 10.1144 6.66699 12V24C6.66699 25.8856 6.66699 26.8284 7.25278 27.4142C7.83857 28 8.78137 28 10.667 28H11.667"
              stroke="#00176A"
              strokeOpacity="0.75"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="16.667"
              y="20"
              width="16.6667"
              height="20"
              rx="2"
              stroke="#00176A"
              strokeOpacity="0.75"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
