import React from "react";
import "./Team.scss";
import Girl from "../../../Resources/windowgirl.svg";
import "../../../Styles/variables.scss";
import TeamCard from "../../../Global Components/TeamCard/TeamCard";
import { currentEventContent } from "../../../Data/currentEventContent";

function Team() {
  return (
    <div className="home-container">
      <div className="home-wrapper">
        <div className="components">
          <div className="greeting-container">
            <div className="img-container">
              <img className="img" src={Girl} alt="image" />
            </div>
            <div className="header">Meet the team!</div>
          </div>

          <div className="team-cards">
            {currentEventContent.team.map((item) => {
              return (
                <TeamCard
                  name={item.name}
                  position={item.position}
                  image={item.photo}
                  year={item.year}
                  department={item.department}
                  linkedIn={item.linkedIn}
                />
              );
            })}
          </div>
          <div className="footer-section">{/* <MailingList /> */}</div>
        </div>
      </div>
    </div>
  );
}

export default Team;
