import React, { useState } from "react";
import "./Signup.scss";
import "../../../Styles/variables.scss";
import errorImage from "../../../Resources/error.svg";

export default function Signup() {
  return (
    <div>
      <h1 style={{ color: "white", textAlign: "center" }}>
        Please use the link in the nav bar above to apply.
      </h1>
    </div>
  );
}

/*export default function Signup({ apiUrl }) {
  const initialValue = "";
  const [error, setError] = useState("");
  const [success, setSuccessMessage] = useState("");
  const [email, setEmail] = useState(initialValue);

  const handleChange = (e) => {
    //update the value with the value in the email field
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //validate the values entered in the email and password fields
    const updatedErrors = validate(email);
    //display errors if any are present
    setError(updatedErrors);
    if (!updatedErrors) {
      const response = await authenticate(email);
      if (response.status === 200) {
        setError("");
        setSuccessMessage(
          "Success! Please check your inbox for an email from president.codejam@mcgilleus.ca.",
        );
      } else if (response.status === 400) {
        setSuccessMessage("");
        setError("An account already exists for this email.");
      } else {
        setSuccessMessage("");
        setError("Something went wrong, please try again.");
      }
    }
  };

  async function authenticate(email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    console.log(email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    console.log(apiUrl + "users/verify-email");
    var output = await fetch(apiUrl + "users/verify-email", requestOptions);
    const text = await output.status;
    console.log(text);
    return output;
  }

  const goBack = () => {
    window.location.href = "/login";
  };

  return (
    <div className="login-container">
      <div className="title-container">
        <div className="description-container">
          Thank you for expressing interest in CodeJam 13!
          <br />
          <br />
          In order to participate in the event, you must be attending a
          post-secondary learning institution in Canada or must have graduated
          in Winter 2024. <br />
          <br />
          After entering your email address below, you will receive an email
          with a <strong>registration code</strong>, and a link to begin the
          application.
          <br />
          <br />
          This will be an application to attend the event. Therefore, if
          accepted, a <strong>$15 entrance fee</strong> to participate must be
          paid. The cost will help us cover the meals offered during the event
          and the CodeJam 13 participants' shirts. Acceptance emails will be
          sent out before the event.
        </div>
        <div className="email-container">
          <div className="email-header">Email</div>
          <div className="input-box">
            <input
              className={error ? "email-box-failure" : "email-box-success"}
              name="email"
              onChange={handleChange}
              placeholder="email@address.com"
              type="email"
              value={email}
            />
          </div>
          <div className="error-messages-container">
            {error ? <img className="error-image" src={errorImage} /> : <></>}
            <div className="error-messages">{error}</div>
          </div>
          <button className="submit-button" onClick={handleSubmit}>
            <b>Get link to application form</b>
          </button>
          <div className="success-message-container">
            {success ? <div className="success-message">{success}</div> : <></>}
          </div>
        </div>
        <div className="description-container">
          If you face any issues during the application process, do not hesitate
          to contact us at:{" "}
          <a href="mailto:codejam@mcgilleus.ca">codejam@mcgilleus.ca</a>
        </div>
      </div>
    </div>
  );
}

//this method validates the email and password values and returns a list containing error messages
const validate = (value) => {
  var errorFound = "";
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!value) {
    errorFound = "Email is required.";
  } else if (!regex.test(value)) {
    errorFound = "Please enter a valid email.";
  } else {
    errorFound = "";
  }
  return errorFound;
};*/
