import React from "react";
import LandingImage from "../../Resources/landing.svg";
import { Link } from "react-router-dom";
import "./Landing.scss";
import Linkedin from "../../Resources/linkedin.svg";
import Facebook from "../../Resources/facebook.svg";
import Instagram from "../../Resources/instagram.svg";
import ButtonCustom from "../Button/ButtonCustom";
function Landing() {
  return (
    <div className="landing-container">
      <div className="img-container">
        <img className="img" src={LandingImage} alt="landingimage" />
      </div>
      <div className="text-container">
        <div className="text-wrapper">
          <div className="body top-header">
            14th Edition | McGill University
          </div>
          <div className="header middle-header title">Code.Jam( )</div>
          <div className="body bottom-header">
            22<sup>nd</sup>-24<sup>th</sup> November 2024
          </div>
          <a
            href="https://forms.gle/hm4kVE18CdFnAmj28"
            target="_blank"
            rel="noreferrer noopener"
          >
            {/* <ButtonCustom title="Apply Now" /> */}
          </a>

          <div className="social-icons-wrapper">
            <a
              href="https://facebook.com/mcgillcodejam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icons" src={Facebook}></img>
            </a>
            <a
              href="https://linkedin.com/company/codejam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icons" src={Linkedin}></img>
            </a>
            <a
              href="https://instagram.com/mcgillcodejam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="social-icons" src={Instagram}></img>
            </a>
          </div>
        </div>
      </div>
    </div>
    // <div className="landing-container">
    //   <div className="img-container">
    //     <img className="img" src={LandingImage} alt="landingimage" />
    //   </div>
    //   <div className="text-container">
    //     <div className="text-wrapper">
    //       <div className="body top-header">12th Edition| McGill University</div>
    //       <div className="header middle-header">Code.Jam( )</div>
    //       <div className="body">November 2022</div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Landing;
