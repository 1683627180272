import React, { useState } from "react";

import "./ErrorModal.scss";

const ErrorModal = ({
  errorMessage,
  toggleModal, // a function that toggles the isShown bool of the modal
}) => {
  return (
    <div className="error-modal-window">
      <div className="topSection">
        <div className="headerText">Dashboard Error</div>
        <div className="subtext">{errorMessage}</div>
        <div className="closeButton" onClick={toggleModal}>
          <svg
            width="46"
            height="45"
            viewBox="0 0 46 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.8506 33.4481L34.1494 11.1493"
              stroke="#8877DC"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M11.8506 11.1495L34.1494 33.4484"
              stroke="#8877DC"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
