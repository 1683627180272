import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import "./Application.scss";
import "../../../Styles/variables.scss";
import showPasswordLogo from "../../../Resources/showPassword.svg";
import hidePasswordLogo from "../../../Resources/hidePassword.svg";
import errorImage from "../../../Resources/error.svg";
import Dropdown from "./Dropdown/Dropdown";
import axios from "axios";
import Select from "react-select";

export default function Application() {
  return (
    <div>
      <h1 style={{ color: "white", textAlign: "center" }}>
        Please use the link in the nav bar above to apply.
      </h1>
    </div>
  );
}

/*export default function Application({ apiUrl }) {
  const initialValues = {
    password: "",
    firstName: "",
    lastName: "",
    pronouns: "",
    github: "",
    devpost: "",
    linkedin: "",
    discord: "",
    academicLevel: "",
    school: "",
    faculty: "",
    major: "",
    academicYear: "",
    expectedGradDate: "",
    teamMembers: "",
    employmentOpportunities: "",
    foodRestrictions: [],
    foodRestrictionsOther: "",
    tshirtSize: "",
    shareResume: "",
    howDidYouHear: "",
    codingBeginner: "",
    twoWords: "",
    codejamGoal: "",
    additionalComments: "",
    resumeFile: "",
    registrationCode: "",
    speakFrench: "",
  };
  const [loading, setLoading] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [pronounsError, setPronounsError] = useState("");
  const [githubError, setGithubError] = useState("");
  const [discordError, setDiscordError] = useState("");
  const [academicLevelError, setAcademicLevelError] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [majorError, setMajorError] = useState("");
  const [academicYearError, setAcademicYearError] = useState("");
  const [expectedGradDateError, setExpectedGradDateError] = useState("");
  const [tshirtSizeError, setTshirtSizeError] = useState("");
  const [hearError, setHearError] = useState("");
  const [codingBeginnerError, setCodingBeginnerError] = useState("");
  const [shareResumeError, setShareResumeError] = useState("");
  const [twoWordsError, setTwoWordsError] = useState("");
  const [codejamGoalError, setCodejamGoalError] = useState("");
  const [resumeFileError, setResumeFileError] = useState("");
  const [registrationCodeError, setRegistrationCodeError] = useState("");
  const [FrenchError, setFrenchError] = useState("");

  const [values, setValues] = useState(initialValues);
  const [passwordVisible, setPasswordVisible] = useState(true);

  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedHowDidYouHear, setSelectedHowDidYouHear] = useState("");
  const [selectedFood, setSelectedFood] = useState([]);
  const [selectedAcademicLevel, setSelectedAcademicLevel] = useState("");
  const [selectedAcademicYear, setSelectedAcademicYear] = useState("");
  const [selectedMajor, setSelectedMajor] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [file, setFile] = useState(null);

  const educationOptions = [
    "College/Cegep",
    "Undergraduate",
    "Graduate Student (masters, phd)",
    "Recent Grad (Winter 2023)",
    "Other",
  ];
  const schoolOptions = [
    "McGill",
    "Concordia",
    "Polytechnique",
    "UdeM",
    "UQAM",
    "ETS",
    "Cegep/College",
    "UoT",
    "Other",
  ];
  const facultyOptions = [
    "Engineering",
    "Science",
    "Arts",
    "Arts-Sci",
    "Other",
  ];
  const majorOptions = [
    "Software Engineering",
    "Computer Engineering",
    "Electrical Engineering",
    "Computer Science",
    "Other",
  ];
  const academicOptions = [
    "U0",
    "U1",
    "U2",
    "U3",
    "U4+",
    "N/A (cegep/graduate)",
    "Other",
  ];
  const gradOptions = ["2023", "2024", "2025", "2026", "2027+"];
  const employmentOptions = ["Internship", "Full-Time", "Part-Time"];
  const foodOptions = [
    "Vegetarian (no meat)",
    "Pescatarian (vegetarian but you eat fish)",
    "Vegan (plant based products only)",
    "Halal (If we cannot provide a halal meal, it will be replaced with vegetarian)",
    "Kosher (If we cannot provide a kosher meal, it will be replaced with vegetarian)",
    "Nut allergy",
    "No sesame",
    "No fish",
    "No pork",
    "No beef",
    "No eggs",
    "No shrimp",
    "Lactose intolerant",
    "Other",
  ];
  const foodOptions2 = [
    { value: "Vegetarian (no meat)", label: "Vegetarian (no meat)" },
    {
      value: "Pescatarian (vegetarian but you eat fish)",
      label: "Pescatarian (vegetarian but you eat fish)",
    },
    {
      value: "Vegan (plant based products only)",
      label: "Vegan (plant based products only)",
    },
    {
      value:
        "Halal (If we cannot provide a halal meal, it will be replaced with vegetarian)",
      label:
        "Halal (If we cannot provide a halal meal, it will be replaced with vegetarian)",
    },
    {
      value:
        "Kosher (If we cannot provide a kosher meal, it will be replaced with vegetarian)",
      label:
        "Kosher (If we cannot provide a kosher meal, it will be replaced with vegetarian)",
    },
    { value: "Nut allergy", label: "Nut allergy" },
    { value: "No sesame", label: "No sesame" },
    { value: "No fish", label: "No fish" },
    { value: "No pork", label: "No pork" },
    { value: "No beef", label: "No beef" },
    { value: "No eggs", label: "No eggs" },
    { value: "No shrimp", label: "No shrimp" },
    { value: "Lactose intolerant", label: "Lactose intolerant" },
    { value: "Other", label: "Other" },
  ];
  const tshirtOptions = ["XS", "S", "M", "L", "XL"];
  const shareWithEmployers = ["Yes", "No"];
  const howDidYouHear = [
    "EUS Involvement Day",
    "SSMU Activies Night",
    "Peers",
    "Attended a previous CodeJam",
    "Facebook",
    "Poster",
    "ECESS",
    "CSUS",
    "LinkedIn",
    "Instagram",
    "CS Listerserv",
    "myCourses",
    "In class announcement",
    "McGill Reddit",
    "Other university reddit",
    "Queer Engineer",
    "Other",
  ];
  const isBeginner = ["Yes", "No"];
  const isFrench = ["Yes", "No"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleSchoolChange = (selectedValue) => {
    setSelectedSchool(selectedValue);
    setValues({ ...values, school: selectedValue });
  };
  const handleHowDidYouHearChange = (selectedValue) => {
    setSelectedHowDidYouHear(selectedValue);
    setValues({ ...values, howDidYouHear: selectedValue });
  };
  const handleMajor = (selectedValue) => {
    setSelectedMajor(selectedValue);
    if (selectedValue === "Other") {
      setValues({ ...values, major: "" });
    } else {
      setValues({ ...values, major: selectedValue });
    }
  };
  const handleAcademicLevel = (selectedValue) => {
    setSelectedAcademicLevel(selectedValue);
    setValues({ ...values, academicLevel: selectedValue });
  };
  const handleFood = (selectedValue) => {
    setSelectedFood(selectedValue);
    setValues({ ...values, foodRestrictions: selectedValue });
  };
  const handleAcademicYear = (selectedValue) => {
    setSelectedAcademicYear(selectedValue);
    setValues({ ...values, academicYear: selectedValue });
  };
  const handleFaculty = (selectedValue) => {
    setSelectedFaculty(selectedValue);
    setValues({ ...values, faculty: selectedValue });
  };
  const handleIsBeginner = (selectedValue) => {
    setValues({ ...values, codingBeginner: selectedValue });
  };
  const handleIsFrench = (selectedValue) => {
    setValues({ ...values, speakFrench: selectedValue });
  };
  const handleTshirtSize = (selectedValue) => {
    setValues({ ...values, tshirtSize: selectedValue });
  };
  const handleShareResume = (selectedValue) => {
    setValues({ ...values, shareResume: selectedValue });
  };
  const handleGradDate = (selectedValue) => {
    setValues({ ...values, expectedGradDate: selectedValue });
  };
  const handleEmploymentOpportunities = (selectedValue) => {
    setValues({ ...values, employmentOpportunities: selectedValue });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setValues({ ...values, resumeFile: file });
    if (file && !file.name.includes(".pdf")) {
      setResumeFileError("Please upload a PDF file");
    } else {
      setResumeFileError("");
    }
  };

  const handleSubmit = async (e) => {
    setSuccess("");
    setError("");
    setLoading("Just a moment...");
    e.preventDefault();
    //validate the values entered in the email and password fields
    const updatedErrors = validate(values);
    //display errors if any are present
    setPasswordError(updatedErrors.password);
    setFirstNameError(updatedErrors.firstName);
    setLastNameError(updatedErrors.lastName);
    setPronounsError(updatedErrors.pronouns);
    setGithubError(updatedErrors.github);
    setDiscordError(updatedErrors.discord);
    setAcademicLevelError(updatedErrors.academicLevel);
    setSchoolError(updatedErrors.school);
    setMajorError(updatedErrors.major);
    setAcademicYearError(updatedErrors.academicYear);
    setExpectedGradDateError(updatedErrors.expectedGradDate);
    setTshirtSizeError(updatedErrors.tshirtSize);
    setHearError(updatedErrors.howDidYouHear);
    setCodingBeginnerError(updatedErrors.codingBeginner);
    setShareResumeError(updatedErrors.shareResume);
    setTwoWordsError(updatedErrors.twoWords);
    setCodejamGoalError(updatedErrors.codejamGoal);
    setResumeFileError(updatedErrors.resumeFile);
    setRegistrationCodeError(updatedErrors.registrationCode);
    setFrenchError(updatedErrors.speakFrench);
    //proceed if no errors are present
    if (
      !updatedErrors.password &&
      !updatedErrors.firstName &&
      !updatedErrors.lastName &&
      !updatedErrors.pronouns &&
      !updatedErrors.github &&
      !updatedErrors.discord &&
      !updatedErrors.twoWords &&
      !updatedErrors.codejamGoal &&
      !updatedErrors.academicLevel &&
      !updatedErrors.school &&
      !updatedErrors.major &&
      !updatedErrors.academicYear &&
      !updatedErrors.expectedGradDate &&
      !updatedErrors.tshirtSize &&
      !updatedErrors.howDidYouHear &&
      !updatedErrors.codingBeginner &&
      !updatedErrors.shareResume &&
      !updatedErrors.resumeFile &&
      !updatedErrors.registrationCode &&
      !updatedErrors.speakFrench
    ) {
      // get all the values entered
      const formData = new FormData();
      formData.append("resume", values.resumeFile);

      const password = values.password;
      const registrationCode = values.registrationCode;
      const firstName = values.firstName;
      const lastName = values.lastName;
      const pronouns = values.pronouns;
      const github = values.github;
      const devpost = values.devpost;
      const linkedin = values.linkedin;
      const discord = values.discord;
      let faculty = values.faculty;
      const educationLevel = values.academicLevel;
      const major = values.major;
      const academicYear = values.academicYear;
      const expectedGraduationYear = values.expectedGradDate;
      const school = values.school;
      if (school != "McGill") {
        faculty = "N/A";
      }
      const teamMembers = values.teamMembers;
      const employmentOpportunities = values.employmentOpportunities;
      let foodRestrictions = values.foodRestrictions.map((val) => {
        return val.value;
      });
      foodRestrictions = foodRestrictions.concat(values.foodRestrictionsOther);
      const size = values.tshirtSize;

      let resumeConsent = false;
      if (values.shareResume === "Yes") {
        resumeConsent = true;
      }
      const hearAbout = values.howDidYouHear;

      let beginnerToCoding = false;
      if (values.codingBeginner === "Yes") {
        beginnerToCoding = true;
      }
      const describeSelf = values.twoWords;
      const projectDescription = values.codejamGoal;
      const additionalComments = values.additionalComments;
      let speakFrench = false;
      if (values.speakFrench === "Yes") {
        speakFrench = true;
      }
      const requestBody = {
        password: password,
        registrationCode: registrationCode,
        firstName: firstName,
        lastName: lastName,
        pronouns: pronouns,
        github: github,
        devpost: devpost,
        linkedin: linkedin,
        discord: discord,
        faculty: faculty,
        educationLevel: educationLevel,
        major: major,
        academicYear: academicYear,
        expectedGraduationYear: expectedGraduationYear,
        school: school,
        teamMembers: teamMembers,
        employmentOpportunities: employmentOpportunities,
        foodRestrictions: foodRestrictions.toString(),
        size: size,
        resumeConsent: resumeConsent,
        hearAbout: hearAbout,
        beginnerToCoding: beginnerToCoding,
        describeSelf: describeSelf,
        projectDescription: projectDescription,
        additionalComments: additionalComments,
        speaksFrench: speakFrench,
        resume: formData,
      };

      for (const key in requestBody) {
        formData.append(key, requestBody[key]);
      }

      axios
        .put(apiUrl + "users/register", formData)
        .then((response) => {
          setLoading("");
          console.log("Response from the server:", response.data);
          if (response.status === 200) {
            setSuccess("Success! We'll be in touch with you soon.");
            setError("");
          } else {
            setSuccess("");
            setError("Something went wrong, please try again.");
          }
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          setLoading("");
          console.error("Error:", error);
          setSuccess("");
          setError("Something went wrong, please try again.");
        });
    } else {
      setLoading("");
      setSuccess("");
      setError("Something went wrong, please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="title-container">
        <h4>Application Form </h4>
        <div className="email-container">
          <div className="password-header">
            <p>
              Registration Code
              {/* <a id="signup" href={"/signup"}>
                here
              </a> *\/}
            </p>
          </div>
          <div className="input-box">
            <input
              className={
                registrationCodeError
                  ? "password-box-failure"
                  : "password-box-success"
              }
              name="registrationCode"
              onChange={handleChange}
              placeholder="The code sent to you by email goes here"
              type="text"
              value={values.registrationCode}
            />
          </div>
          <div className="error-messages-container">
            {registrationCodeError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{registrationCodeError}</div>
          </div>
          <div className="password-header">
            <p>Password</p>
          </div>
          <div className="input-box">
            <input
              className={
                passwordError ? "password-box-failure" : "password-box-success"
              }
              id="password"
              name="password"
              onChange={handleChange}
              placeholder="Password"
              type={passwordVisible ? "text" : "password"}
              value={values.password}
            />
          </div>
          <div className="error-messages-container">
            {passwordError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{passwordError}</div>
          </div>
          <div className="password-header">
            <p>First Name</p>
          </div>
          <div className="input-box">
            <input
              className={
                firstNameError ? "password-box-failure" : "password-box-success"
              }
              name="firstName"
              onChange={handleChange}
              placeholder="John"
              type="text"
              value={values.firstName}
            />
          </div>
          <div className="error-messages-container">
            {firstNameError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{firstNameError}</div>
          </div>
          <div className="password-header">
            <p>Last Name</p>
          </div>
          <div className="input-box">
            <input
              className={
                lastNameError ? "password-box-failure" : "password-box-success"
              }
              name="lastName"
              onChange={handleChange}
              placeholder="Doe"
              type="text"
              value={values.lastName}
            />
          </div>
          <div className="error-messages-container">
            {lastNameError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{lastNameError}</div>
          </div>
          <div className="password-header">
            <p>Pronouns</p>
          </div>
          <div className="input-box">
            <input
              className={
                pronounsError ? "password-box-failure" : "password-box-success"
              }
              name="pronouns"
              onChange={handleChange}
              placeholder="He/Him, She/Her, They/Them"
              type="text"
              value={values.pronouns}
            />
          </div>
          <div className="error-messages-container">
            {pronounsError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{pronounsError}</div>
          </div>
          <div className="password-header">
            <p>Github</p>
          </div>
          <div className="input-box">
            <input
              className={
                githubError ? "password-box-failure" : "password-box-success"
              }
              name="github"
              onChange={handleChange}
              placeholder="https://github.com/JohnDoe"
              type="text"
              value={values.github}
            />
          </div>
          <div className="error-messages-container">
            {githubError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{githubError}</div>
          </div>
          <div className="password-header">
            <p>Devpost (Optional)</p>
          </div>
          <div className="input-box">
            <input
              className={"password-box-success"}
              name="devpost"
              onChange={handleChange}
              placeholder="https://devpost.com/JohnDoe"
              type="text"
              value={values.devpost}
            />
          </div>
          <div className="password-header">
            <p>LinkedIn (Optional)</p>
          </div>
          <div className="input-box">
            <input
              className={"password-box-success"}
              name="linkedin"
              onChange={handleChange}
              placeholder="https://www.linkedin.com/in/johndoe/"
              type="text"
              value={values.linkedin}
            />
          </div>
          <div className="password-header">
            <p>Discord</p>
          </div>
          <div className="input-box">
            <input
              className={
                discordError ? "password-box-failure" : "password-box-success"
              }
              name="discord"
              onChange={handleChange}
              placeholder="johndoegamergod"
              type="text"
              value={values.discord}
            />
          </div>
          <div className="error-messages-container">
            {discordError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{discordError}</div>
          </div>
          <div className="password-header">
            <p>Academic Level</p>
          </div>
          <Dropdown options={educationOptions} onChange={handleAcademicLevel} />
          {selectedAcademicLevel === "Other" && (
            <div className="input-box">
              <input
                className={
                  academicLevelError
                    ? "password-box-failure"
                    : "password-box-success"
                }
                name="academicLevel"
                onChange={handleChange}
                placeholder="Other"
                type="text"
                value={values.academicLevel}
              />
            </div>
          )}
          <div className="error-messages-container">
            {academicLevelError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{academicLevelError}</div>
          </div>
          <div className="password-header">
            <p>School</p>
          </div>
          <Dropdown options={schoolOptions} onChange={handleSchoolChange} />
          {selectedSchool === "McGill" && (
            <div>
              <div className="password-header">
                <p>Faculty</p>
              </div>
              <Dropdown options={facultyOptions} onChange={handleFaculty} />
              {selectedFaculty === "Other" && (
                <div>
                  <div className="input-box">
                    <input
                      className={"password-box-success"}
                      name="faculty"
                      onChange={handleChange}
                      placeholder="Other"
                      type="text"
                      value={values.faculty}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="error-messages-container">
            {schoolError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{schoolError}</div>
          </div>
          {selectedSchool === "Other" && (
            <div className="input-box">
              <input
                className={
                  firstNameError
                    ? "password-box-failure"
                    : "password-box-success"
                }
                name="school"
                onChange={handleChange}
                placeholder="Other"
                type="text"
                value={values.school}
              />
            </div>
          )}
          <div className="password-header">
            <p>Major</p>
          </div>
          <Dropdown options={majorOptions} onChange={handleMajor} />
          {selectedMajor === "Other" && (
            <div className="input-box">
              <input
                className={
                  majorError ? "password-box-failure" : "password-box-success"
                }
                name="major"
                onChange={handleChange}
                placeholder="Other"
                type="text"
                value={values.major}
              />
            </div>
          )}
          <div className="error-messages-container">
            {majorError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{majorError}</div>
          </div>
          <div className="password-header">
            <p>Academic Year</p>
          </div>
          <Dropdown options={academicOptions} onChange={handleAcademicYear} />
          {selectedAcademicYear === "Other" && (
            <div className="input-box">
              <input
                className={
                  academicYearError
                    ? "password-box-failure"
                    : "password-box-success"
                }
                name="academicYear"
                onChange={handleChange}
                placeholder="Other"
                type="text"
                value={values.academicYear}
              />
            </div>
          )}
          <div className="error-messages-container">
            {academicYearError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{academicYearError}</div>
          </div>
          <div className="password-header">
            <p>Expected Graduation Date</p>
          </div>
          <Dropdown options={gradOptions} onChange={handleGradDate} />
          <div className="error-messages-container">
            {expectedGradDateError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{expectedGradDateError}</div>
          </div>
          <div className="password-header">
            <p>
              Please input the full names of each of the members you would like
              to apply with. This is for us to try to ensure that everyone on
              your team passes the application process. Please note that each
              member of your team must apply separately. (Optional)
            </p>
          </div>
          <div className="input-box">
            <input
              className={"password-box-success"}
              name="teamMembers"
              onChange={handleChange}
              placeholder="Teammate 1, Teammate 2, Teammate 3"
              type="text"
              value={values.teamMembers}
            />
          </div>
          <div className="password-header">
            <p>
              What type of employment opportunities are you interested in
              (Optional)?
            </p>
          </div>
          <Dropdown
            options={employmentOptions}
            onChange={handleEmploymentOpportunities}
          />
          <div className="password-header">
            <p>Food Restrictions (Optional)</p>
          </div>
          <Select
            options={foodOptions2}
            onChange={handleFood}
            isMulti={true}
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: "transparent",
                border: "2px solid #303B62",
                fontSize: "24px",
              }),
              valueContainer: (provided) => ({
                ...provided,
                background: "transparent",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: "#303B62",
                color: "white",
                fontSize: "20px",
              }),
              multiValue: (provided) => ({
                ...provided,
                background: "#303B62",
                color: "white",
              }),
              menu: (provided) => ({
                ...provided,
                background: "transparent",
                marginTop: "-10px",
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                color: "white",
              }),
            }}
          />
          {selectedFood.find((pair) => {
            return pair.label === "Other";
          }) && (
            <div className="input-box">
              <input
                className={"password-box-success"}
                name="foodRestrictionsOther"
                onChange={handleChange}
                placeholder="Other"
                type="text"
                value={values.foodRestrictionsOther}
              />
            </div>
          )}
          <div
            className="password-header"
            style={{
              marginTop: "40px",
            }}
          >
            <p>T-Shirt Size</p>
          </div>
          <Dropdown options={tshirtOptions} onChange={handleTshirtSize} />
          <div className="error-messages-container">
            {tshirtSizeError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{tshirtSizeError}</div>
          </div>
          <div className="password-header">
            <p>Do you consent to us sharing your resume with employers?</p>
          </div>
          <Dropdown options={shareWithEmployers} onChange={handleShareResume} />
          <div className="error-messages-container">
            {shareResumeError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{shareResumeError}</div>
          </div>
          <div className="password-header">
            <p>How did you hear about CodeJam?</p>
          </div>
          <Dropdown
            options={howDidYouHear}
            onChange={handleHowDidYouHearChange}
          />
          {selectedHowDidYouHear === "Other" && (
            <div className="input-box">
              <input
                className={
                  hearError ? "password-box-failure" : "password-box-success"
                }
                name="howDidYouHear"
                onChange={handleChange}
                placeholder="Other"
                type="text"
                value={values.howDidYouHear}
              />
            </div>
          )}
          <div className="error-messages-container">
            {hearError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{hearError}</div>
          </div>
          <div className="password-header">
            <p>Would you consider yourself a beginner to coding?</p>
          </div>
          <Dropdown options={isBeginner} onChange={handleIsBeginner} />
          <div className="error-messages-container">
            {codingBeginnerError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{codingBeginnerError}</div>
          </div>
          <div className="password-header">
            <p>Do you speak French?</p>
          </div>
          <Dropdown options={isFrench} onChange={handleIsFrench} />
          <div className="error-messages-container">
            {FrenchError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{FrenchError}</div>
          </div>
          <div className="password-header">
            <p>Describe yourself in two words</p>
          </div>
          <div className="input-box">
            <input
              className={
                twoWordsError ? "password-box-failure" : "password-box-success"
              }
              name="twoWords"
              onChange={handleChange}
              placeholder="Smart, Cool"
              type="text"
              value={values.twoWords}
            />
          </div>
          <div className="error-messages-container">
            {twoWordsError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{twoWordsError}</div>
          </div>
          <div className="password-header">
            <p>
              Describe what kind of project you would like to make at CodeJam.
              What technologies you would use for your project (If you are a
              beginner, what would you like to learn from CodeJam)
            </p>
          </div>
          <div className="input-box">
            <input
              className={
                codejamGoalError
                  ? "password-box-failure"
                  : "password-box-success"
              }
              name="codejamGoal"
              onChange={handleChange}
              placeholder="A project involving..."
              type="text"
              value={values.codejamGoal}
            />
          </div>
          <div className="error-messages-container">
            {codejamGoalError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{codejamGoalError}</div>
          </div>
          <div className="password-header">
            <p>Additional comments (Optional)</p>
          </div>
          <div className="input-box">
            <input
              className={"password-box-success"}
              name="additionalComments"
              onChange={handleChange}
              placeholder=""
              type="text"
              value={values.additionalComments}
            />
          </div>
          <div className="password-header">
            <p>Submit your resume (PDF Format)</p>
          </div>
          <form>
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={handleFileChange}
            />
          </form>
          <div className="error-messages-container">
            {resumeFileError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{resumeFileError}</div>
          </div>
          <button className="login-button" onClick={handleSubmit}>
            <b>Submit Form</b>
          </button>
          <div className="success-message-container">
            {success ? <div className="success-message">{success}</div> : <></>}
          </div>
          <div className="success-message-container">
            {error ? <div className="not-success">{error}</div> : <></>}
          </div>
          <div className="success-message-container">
            {loading ? <div className="success-message">{loading}</div> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
}

//this method validates the email and password values and returns a list containing error messages
const validate = (values) => {
  const errorlist = {
    password: "",
    firstName: "",
    lastName: "",
    pronouns: "",
    github: "",
    discord: "",
    academicLevel: "",
    school: "",
    major: "",
    academicYear: "",
    expectedGradDate: "",
    tshirtSize: "",
    shareResume: "",
    howDidYouHear: "",
    codingBeginner: "",
    twoWords: "",
    codejamGoal: "",
    resumeFile: "",
    registrationCode: "",
    speakFrench: "",
  };
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!values.password) {
    errorlist.password = "Password is required.";
  } else {
    errorlist.password = "";
  }
  if (!values.firstName) {
    errorlist.firstName = "First name is required.";
  } else {
    errorlist.firstName = "";
  }
  if (!values.lastName) {
    errorlist.lastName = "Last name is required.";
  } else {
    errorlist.lastName = "";
  }
  if (!values.pronouns) {
    errorlist.pronouns = "Pronouns are required.";
  } else {
    errorlist.pronouns = "";
  }
  if (!values.github) {
    errorlist.github = "A github account is required.";
  } else {
    errorlist.github = "";
  }
  if (!values.discord) {
    errorlist.discord = "Please enter your discord account name.";
  } else {
    errorlist.discord = "";
  }
  if (!values.academicLevel) {
    errorlist.academicLevel = "Please choose an academic level.";
  } else {
    errorlist.academicLevel = "";
  }
  if (!values.school) {
    errorlist.school = "Please choose a school.";
  } else {
    errorlist.school = "";
  }
  if (!values.major) {
    errorlist.major = "Please choose a major.";
  } else {
    errorlist.major = "";
  }
  if (!values.academicYear) {
    errorlist.academicYear = "Please choose a value.";
  } else {
    errorlist.academicYear = "";
  }
  if (!values.expectedGradDate) {
    errorlist.expectedGradDate = "Please choose a value.";
  } else {
    errorlist.expectedGradDate = "";
  }
  if (!values.tshirtSize) {
    errorlist.tshirtSize = "Please choose a value.";
  } else {
    errorlist.tshirtSize = "";
  }
  if (!values.shareResume) {
    errorlist.shareResume = "Please choose a value.";
  } else {
    errorlist.shareResume = "";
  }
  if (!values.howDidYouHear) {
    errorlist.howDidYouHear = "Please choose a value.";
  } else {
    errorlist.howDidYouHear = "";
  }
  if (!values.codingBeginner) {
    errorlist.codingBeginner = "Please choose a value.";
  } else {
    errorlist.codingBeginner = "";
  }
  if (!values.twoWords) {
    errorlist.twoWords = "Please give us the two words that describe you best.";
  } else {
    errorlist.twoWords = "";
  }
  if (!values.codejamGoal) {
    errorlist.codejamGoal = "Please enter a value.";
  } else {
    errorlist.codejamGoal = "";
  }
  if (!values.resumeFile) {
    errorlist.resumeFile = "Share your resume.";
  } else {
    errorlist.resumeFile = "";
  }
  if (!values.registrationCode) {
    errorlist.registrationCode =
      "Enter the registration code sent to you by email";
  } else {
    errorlist.registrationCode = "";
  }
  if (!values.speakFrench) {
    errorlist.speakFrench = "Please enter a value.";
  } else {
    errorlist.speakFrench = "";
  }
  return errorlist;
};*/
