import React from "react";
import "./Home.scss";
import Landing from "../../../Global Components/Landing/Landing";
import "../../../Styles/variables.scss";
import Card from "../../../Global Components/Card/Card.jsx";
import { FAQContent } from "../../../Data/FAQContent";
import WhatIsCodeJamImage from "../../../Resources/whatiscodejam.svg";
import Blob from "../../../Global Components/Blob";
import Accordion from "../../../Global Components/Accordion/Accordion";
import SponsorData from "../../../Data/SponsorData";
import Sponsors from "../../../Global Components/Sponsors/Sponsors";
import MailingList from "../../../Global Components/MailingList/MailingList";

function Home() {
  return (
    <div className="home-container">
      <div className="home-wrapper">
        <Landing />
        <div className="components">
          <div className="whatiscodejam-container">
            <div className="illustration-container">
              <img
                className="illustration"
                src={WhatIsCodeJamImage}
                alt="What Is CodeJam Image"
              />
            </div>

            <div className="text-container">
              <div className="heading">What is Code.Jam( )?</div>

              <div className="introduction-text">
                <span id="bold-codejam">Code.Jam( )</span> is the annual
                hackathon run by the McGill Electrical, Computer, and Software
                Student Society in our very own Trottier Engineering Building.
                We provide an engaging environment for students to learn and
                have fun over a weekend of coding!
              </div>
            </div>
          </div>
          {/* <div className="card-components">
            <Card
              number="1"
              title="Gain Valuable Experience"
              description="Gain experience in event planning and logistics, which will help you in your future endeavours."
            />

            <Card
              number="2"
              title="Jam with others"
              description="You will be working with passionate individuals who are dedicated to making the event a success. This is a great opportunity to make new friends and expand your network."
            />
          </div>
          <div className="card-components">
            <Card
              number="3"
              title="Make a Positive Impact"
              description="Code.Jam() provides an engaging environment for students to learn and have fun over a weekend of coding. As a member of the team, you will be contributing to an event that provides a positive impact on the community."
              // tinytext="*while stocks last"
            />

            <Card
              number="4"
              title="Develop Leadership Skills"
              description="You will have the opportunity to develop leadership skills, such as decision-making, problem-solving, and communication skills. These skills are highly valuable in both personal and professional settings."
            />
          </div> */}
          <div className="why-join-codejam-header">Why Attend CodeJam?</div>
          <div className="card-components">
            <Card
              number="1"
              title="Build coding skills"
              description="Build an awesome project with your team over the course of the weekend (and get feedback and mentoring throughout)!"
            />

            <Card
              number="2"
              title="Jam with others"
              description="Meet like-minded hackers and connect with companies looking to hire their next batch of talented developers."
            />
          </div>
          <div className="card-components">
            <Card
              number="3"
              title="Swag"
              description="Receive a swag package* that includes a limited CodeJam 14 t-shirt, stickers and sponsored items!"
              tinytext="*while stocks last"
            />

            <Card
              number="4"
              title="Prizes"
              description="Large cash prizes will be up for grabs! More details about the prize breakdown to be determined."
            />
          </div>
          <div className="faq-header">Frequently Asked Questions</div>
          <Accordion data={FAQContent} />
        </div>
        <div className="footer-section">
          <MailingList />
          <Blob right="true" top="95%" width="40%" />
          <Blob left="true" top="10%" width="40%" />
        </div>
      </div>
      <div className="why-join-codejam-header" style={{ marginTop: "70px" }}>
        CodeJam 13 Sponsors
      </div>
      <Sponsors data={[SponsorData[0]]} />
      <div className="want-to-sponsor">
        Interested in Sponsoring? Email us at{" "}
        <a href="mailto:sponsor.codejam@mcgilleus.ca">
          sponsor.codejam@mcgilleus.ca
        </a>
      </div>
    </div>
  );
}

export default Home;
