import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./DashboardSidebar.scss";
import CodeJamLogo from "../../../Resources/logo.svg";
import axios from "axios";

// TODO: update these icons, currently just using PNGs because that's all I could get
import HomeIcon from "../../../Resources/Icons/Home_Icon.svg";
import ProfileIcon from "../../../Resources/Icons/Person_Icon.svg";
import TeamIcon from "../../../Resources/Icons/Team_Icon.svg";
import SearchIcon from "../../../Resources/Icons/Search_Icon.svg";
import ScheduleIcon from "../../../Resources/Icons/Schedule_Icon.png";
import SettingsIcon from "../../../Resources/Icons/Settings_Icon.png";
import LogOutIcon from "../../../Resources/Icons/LogOut_Icon.png";
import MenuIcon from "../../../Resources/Icons/Menu_Icon.svg";
const DashboardSidebar = ({ currentUser, apiUrl }) => {
  const location = useLocation();

  // IMPORTANT: This is whether or not the check in button will show up. Will automatically be set to false if it's too early for the check in
  // turn it off here once the check in time is over:
  const [showCheckIn, setShowCheckIn] = useState(false);

  // only show the withdraw button if the user is already checked in AND if showCheckIn is true, that happens later
  const [showWithdraw, setShowWithdraw] = useState(false);

  // only show the button after noon on saturday
  const now = new Date(Date.now());
  if (showCheckIn && now.getDate() < 18) {
    setShowCheckIn(false);
  }
  if (showCheckIn && now.getDate() == 18 && now.getHours() < 12) {
    setShowCheckIn(false);
  }

  // check the user's team to see if they've checked in, if so allow withdrawal
  if (showWithdraw == false && currentUser?.teamID && currentUser?.teamID > 0) {
    axios
      .get(apiUrl + `teams/${currentUser.teamID}/`)
      .then((response) => {
        setShowWithdraw(response.data.data.isCheckedIn1);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  // page will be something like /dashboard/home, we just want 'home'
  const page = location.pathname.split("/")[2];

  // whether or not the sidebar is open on a mobile device
  const [open, setOpen] = useState(false);

  // toggle whether the sidebar is open on mobile
  const toggleSidebar = () => setOpen(!open);

  return (
    <>
      <div
        className="sidebar-background"
        data-open={open}
        onClick={toggleSidebar}
      ></div>
      <a className="sidebar-button" onClick={toggleSidebar}>
        <div className="sidebar-button--container">
          <img src={MenuIcon} />
        </div>
      </a>
      <nav className="sidebar" data-open={open}>
        <div className="sidebar-header">
          <img src={CodeJamLogo} />
          <h2>Code.Jam()</h2>
          <h1>Hacker Dashboard</h1>
        </div>

        <div className="sidebar-profile">
          <img src={currentUser.picture} />
          <h1
            style={
              Math.max(
                currentUser.firstName.length,
                currentUser.lastName.length,
              ) > 20
                ? {
                    fontSize: `${
                      (20 /
                        Math.max(
                          currentUser.firstName.length,
                          currentUser.lastName.length,
                        )) *
                      28
                    }px`,
                  }
                : {}
            }
          >
            {currentUser.firstName} {currentUser.lastName}
          </h1>
        </div>

        <ul className="sidebar-nav sidebar-nav--main">
          {/** IMPORTANT! This link will show up automatically when the check in form opens, but
           * DOESN'T CLOSE ON ITS OWN! So someone has to turn it off
           */}
          {showCheckIn ? (
            <li>
              <Link
                className="sidebar-link sidebar--checkin"
                data-selected={page === "checkIn"}
                onClick={toggleSidebar}
                to="/dashboard/checkIn"
              >
                <span>Check In!</span>
              </Link>
            </li>
          ) : null}

          {/**
           * This button will only show up if the team has checked in, and the check in period is still open
           */}
          {showCheckIn && showWithdraw ? (
            <li>
              <Link
                className="sidebar-link sidebar--checkin sidebar--withdraw"
                data-selected={page === "withdraw"}
                onClick={toggleSidebar}
                to="/dashboard/withdraw"
              >
                <span>Withdraw</span>
              </Link>
            </li>
          ) : null}

          <li>
            <Link
              className="sidebar-link"
              data-selected={page === "profile"}
              onClick={toggleSidebar}
              to="/dashboard/profile"
            >
              <img src={ProfileIcon} />
              <span>Profile</span>
            </Link>
          </li>
          <li>
            <Link
              className="sidebar-link"
              data-selected={page === "team"}
              onClick={toggleSidebar}
              to={
                currentUser.teamID && currentUser.teamID > 0
                  ? "/dashboard/team/" + currentUser.teamID
                  : "/dashboard/editTeam/create"
              }
            >
              <img src={TeamIcon} />
              <span>Team</span>
            </Link>
          </li>
          <li>
            <Link
              className="sidebar-link"
              data-selected={page === "team-browser"}
              onClick={toggleSidebar}
              to="/dashboard/team-browser"
            >
              <img src={SearchIcon} />
              <span>Team Browser</span>
            </Link>
          </li>
          {/* <li>
            <Link
              className="sidebar-link"
              data-selected={page === "schedule"}
              onClick={toggleSidebar}
              to="/dashboard/schedule"
            >
              <img src={ScheduleIcon} />
              <span>Schedule</span>
            </Link>
          </li> */}
        </ul>

        <ul className="sidebar-nav sidebar-nav--bottom">
          <li>
            <Link
              className="sidebar-link"
              onClick={() => {
                //delete cookie
                localStorage.removeItem("token");
                //reload homepage
                window.open(window.location.origin, "_self");
              }}
              to="/"
            >
              <img src={LogOutIcon} />
              <span>Log Out</span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default DashboardSidebar;
