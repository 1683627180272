import React, { useState } from "react";
import Linkedin from "../../Resources/linkedin.svg";
import Facebook from "../../Resources/facebook.svg";
import Instagram from "../../Resources/instagram.svg";
import MailingListRobot from "../../Resources/MailingListRobot.svg";
import "./MailingList.scss";
import Button_TextInput from "../Button_TextInput/Button_TextInput";

const MailingList = () => {
  // state could be init (show input for email),
  // success (show that the email was added),
  // or error (show the failure to get the email)
  const [state, setState] = useState("init");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    // no page reload
    event.preventDefault();
    console.log("sending email");
    // get the email
    const email = event.target.querySelector(".textInput").value;
    // disable the input
    setLoading(true);
    // make a request to the server to add the email to the mailing list
    const response = await fetch(
      "https://mailing-list-manager.netlify.app/.netlify/functions/mailinglist",
      {
        method: "POST",
        body: JSON.stringify({ email }),
      },
    );

    const data = await response.json();

    // loading is done
    setLoading(false);

    // check to see if the request was successful
    if (data.status === true) {
      setState("success");
    } else {
      setState("error");
      setErrorMsg(data.message);
    }
  };

  return (
    <div className="mailing-list-wrapper">
      <img className="mailing-robot" src={MailingListRobot}></img>
      <div className="not-image-part">
        {/* The inside of the not image part varies depending on state,
        only show the form if an email hasn't been successfully added */}

        {state === "success" ? (
          <div className="mailing-list-caption">
            <div className="caption-header">Done!</div>
            <div className="caption-subheading">
              You've been added to the mailing list!
            </div>
          </div>
        ) : (
          <>
            <div className="mailing-list-caption">
              <div className="caption-header">Before you go,</div>
              <div className="caption-subheading">
                <strong>Subscribe</strong> to our mailing list to stay in the
                loop!
              </div>
            </div>
          </>
        )}

        <form
          netlify
          name="mailingList"
          method="POST"
          onSubmit={onSubmit}
          style={{ opacity: state === "success" ? 0 : 1 }}
        >
          <input hidden name="form-name" value="mailingList" />
          <Button_TextInput
            inputPlaceholderText={"Email Address"}
            buttonText={"Subscribe"}
            name="email"
            type="email"
            disabled={loading || state === "success"}
            error={errorMsg}
          />
        </form>
      </div>
    </div>
  );
};

export default MailingList;
