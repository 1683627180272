import react, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

import UploadPFPIcon from "../../../Resources/Icons/UploadPFP.png";

import "./Profile.scss";
import "./EditProfile.scss";

const EditProfile = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  const navigate = useNavigate();

  // load the default data from the current user
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [pronouns, setPronouns] = useState(currentUser.pronouns);
  const [school, setSchool] = useState(currentUser.schoolOrCompany);
  const [program, setProgram] = useState(currentUser.programOrPosition);
  const [bio, setBio] = useState(currentUser.bio);
  const [picture, setPicture] = useState(currentUser.picture);

  const [discord, setDiscord] = useState(currentUser.discord);
  const [linkedin, setLinkedin] = useState(currentUser.linkedin);
  const [devpost, setDevpost] = useState(currentUser.devpost);
  const [github, setGithub] = useState(currentUser.github);
  const [instagram, setInstagram] = useState(currentUser.instagram);
  const [twitter, setTwitter] = useState(currentUser.twitter);

  // when the form submits
  const saveChanges = async (event) => {
    event.preventDefault();

    try {
      // submit the new data to the update profile endpoint
      await axios.put(
        apiUrl + "users/updateProfile",
        {
          firstName,
          lastName,
          pronouns,
          schoolOrCompany: school,
          programOrPosition: program,
          bio,
          picture,

          discord,
          linkedin,
          devpost,
          github,
          instagram,
          twitter,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // currentUser values should be updated to match to avoid the need for a reload
      currentUser.firstName = firstName;
      currentUser.lastName = lastName;
      currentUser.pronouns = pronouns;
      currentUser.schoolOrCompany = school;
      currentUser.programOrPosition = program;
      currentUser.bio = bio;
      currentUser.picture = picture;

      currentUser.discord = discord;
      currentUser.linkedin = linkedin;
      currentUser.devpost = devpost;
      currentUser.github = github;
      currentUser.instagram = instagram;
      currentUser.twitter = twitter;

      // success! redirect to the profile page
      navigate("/dashboard/profile");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  // upload a profile picture
  const uploadPfp = () => {
    // create a file input that only allows images
    const filePicker = document.createElement("input");
    filePicker.type = "file";
    filePicker.accept = "image/*";

    // wait to read the input from the file picker
    filePicker.addEventListener("change", async (event) => {
      // now use an image element to resize this image, and then convert back to a data url with a canvas...
      const img = new Image();
      img.src = URL.createObjectURL(filePicker.files[0]);

      await img.decode();

      const canvas = document.createElement("canvas");

      canvas.width = 128;
      canvas.height = 128;
      const g = canvas.getContext("2d");

      g.drawImage(img, 0, 0, 128, 128);

      // finally, we have the resized image!
      setPicture(canvas.toDataURL());

      document.body.removeChild(filePicker);
    });

    // add it to the document, activate, then remove it
    document.body.appendChild(filePicker);
    filePicker.click();
  };

  return (
    <div className="profile-page--wrapper">
      <div className="profile-page">
        <h1 className="profile--title">Edit Profile</h1>

        <article className="profile--main">
          <div className="profile--main-content edit">
            <form className="profile-edit--form" onSubmit={saveChanges}>
              {/* the form is a grid, with the labels coming before the inputs */}
              <label for="ep-first-name">First Name</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-first-name"
                value={firstName}
                onInput={(event) => {
                  setFirstName(event.target.value);
                }}
              />

              <label for="ep-last-name">Last Name</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-last-name"
                value={lastName}
                onInput={(event) => {
                  setLastName(event.target.value);
                }}
              />

              <label for="ep-pronouns">Pronouns</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-pronouns"
                value={pronouns}
                onInput={(event) => {
                  setPronouns(event.target.value);
                }}
              />

              <label for="ep-school">School</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-school"
                value={school}
                onInput={(event) => {
                  setSchool(event.target.value);
                }}
              />

              <label for="ep-program">Program</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-program"
                value={program}
                onInput={(event) => {
                  setProgram(event.target.value);
                }}
              />

              <label for="ep-bio">Bio</label>
              <textarea
                class="profile-edit--text-input profile-edit--textarea"
                id="ep-bio"
                value={bio}
                onInput={(event) => {
                  setBio(event.target.value);
                }}
              />

              <label className="profile-edit--full-width">
                Account Usernames
              </label>

              <label for="ep-discord">Discord</label>
              <input
                type="text"
                className="profile-edit--text-input ep-at"
                id="ep-discord"
                value={"@" + discord}
                onInput={(event) => {
                  if (event.target.value == "") event.target.value = "@";
                  setDiscord(event.target.value.substring(1));
                }}
              />

              <label for="ep-github">GitHub URL</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-github"
                value={github}
                onInput={(event) => {
                  setGithub(event.target.value);
                }}
              />

              <label for="ep-devpost">Devpost URL</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-devpost"
                value={devpost}
                onInput={(event) => {
                  setDevpost(event.target.value);
                }}
              />

              <label for="ep-linkedin">LinkedIn URL</label>
              <input
                type="text"
                className="profile-edit--text-input"
                id="ep-linkedin"
                value={linkedin}
                onInput={(event) => {
                  setLinkedin(event.target.value);
                }}
              />

              <label for="ep-instagram">Instagram</label>
              <input
                type="text"
                className="profile-edit--text-input ep-at"
                id="ep-instagram"
                value={"@" + instagram}
                onInput={(event) => {
                  if (event.target.value == "") event.target.value = "@";
                  setInstagram(event.target.value.substring(1));
                }}
              />

              <label for="ep-twitter">Twitter</label>
              <input
                type="text"
                className="profile-edit--text-input ep-at"
                id="ep-twitter"
                value={"@" + twitter}
                onInput={(event) => {
                  if (event.target.value == "") event.target.value = "@";
                  setTwitter(event.target.value.substring(1));
                }}
              />

              <button className="ep-save--button">Save</button>
            </form>
          </div>
        </article>
      </div>
    </div>
  );
};

export default EditProfile;
