import * as React from "react";
import { useState } from "react";
import "./Button_TextInput.scss";

const Button_TextInput = ({
  inputPlaceholderText,
  buttonText,
  onClick,
  name,
  type,
  disabled,
  error,
}) => {
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="button-textinput">
      <div className="whole-container">
        <input
          name={name} //for netlify
          className={error != "" ? "textInput textInput--error" : "textInput"}
          placeholder={inputPlaceholderText}
          onChange={handleChange}
          type={type}
          disabled={disabled}
        />
        <button className="button" onClick={onClick} type="submit">
          {buttonText}
        </button>
      </div>
      {/* if there's an error, show this section */}
      {error != "" ? <p className="error">{error}</p> : <></>}
    </div>
  );
};

export default Button_TextInput;
