import React, { useState } from "react";
import InfoCard from "./InfoCard";
import TabCard from "./TabCard";
import "./Tabs.scss";
import Sponsors from "../Sponsors/Sponsors";
import SponsorData from "../../Data/SponsorData";
import TeamCard from "../TeamCard/TeamCard";

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState("codejam13");

  return (
    <div className="Tabs">
      <ul className="nav">
        {props.data.map((item) => {
          // Set to current event's ID to hide
          if (item.id == "codejam14") {
            return null;
          } else {
            return (
              <TabNavItem
                title={item.title}
                id={item.id}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            );
          }
        })}
      </ul>

      <div className="outlet">
        {props.data.map((item) => {
          return (
            <TabContent id={item.id} activeTab={activeTab}>
              <div className="event-info">
                <InfoCard
                  edition={item.edition}
                  codejam={item.title}
                  date={item.date}
                  onClick={() => {
                    window.open(item.link, "_blank", "noopener,noreferrer");
                  }}
                />
                <TabCard
                  line1={item.participants}
                  line2={item.prize}
                  line3={item.location}
                />
              </div>
              <Sponsors
                data={SponsorData.filter((sponsorList) => {
                  return item.id == sponsorList.id;
                })}
              />
              {/* align the team-cards in the centre */}
              <div className="team-cards">
                {item.team?.map((member) => {
                  return (
                    <TeamCard
                      name={member.name}
                      position={member.position}
                      image={member.photo}
                      year={member.year}
                      department={member.department}
                      linkedIn={member.linkedIn}
                    />
                  );
                })}
              </div>
            </TabContent>
          );
        })}
      </div>
    </div>
  );
};

const TabNavItem = ({ id, title, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
      {title}
    </li>
  );
};

const TabContent = ({ id, activeTab, children }) => {
  return activeTab === id ? <div className="TabContent">{children}</div> : null;
};

export default Tabs;
