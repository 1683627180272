import React from "react";
import "./Card.scss";

function Card(props) {
  return (
    <div className="card-container">
      <div className="text">
        <p className="number">{props.number}</p>
        <p className="title">{props.title}</p>
        <p className="subheading">{props.description}</p>
        <p className="asterisk">{props.tinytext}</p>
      </div>
    </div>
  );
}

export default Card;
