import "./Styles/App.scss";
import Website from "./Pages/Website/Website";

import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";

const getToken = () => {
  const tokenString = localStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const apiUrl = "https://codejam-dashboard.onrender.com/api/";
// const apiUrl = "http://localhost:4000/api/";

if (apiUrl === "https://codejam-dashboard.onrender.com/api/") {
  console.error = (message) => {
    // Do nothing to suppress warnings
  };
  console.log = (message) => {
    // Do nothing to suppress warnings
  };
}

function App() {
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
    console.log(token);
  };

  return (
    <Router>
      <div className={"app-container"}>
        <Routes>
          <Route
            exact
            path="/*"
            element={
              <Website token={token} saveToken={saveToken} apiUrl={apiUrl} />
            }
          />
          <Route
            path="/dashboard/*"
            element={
              <Dashboard token={token} saveToken={saveToken} apiUrl={apiUrl} />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
