import React from "react";
import "./TeamCard.scss";
function TeamCard(props) {
  return (
    <a
      className="container"
      href={props.linkedIn}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="faceBorder">
        <img className="face" src={props.image}></img>
      </div>

      <div className="text">
        <p className="name">{props.name}</p>
        <p className="subheading">
          {props.year}, {props.department}
        </p>
        <p className="subheading">{props.position}</p>
      </div>
    </a>
  );
}

export default TeamCard;
