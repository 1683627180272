import React from "react";
import "./LandAck.scss";

function LandAck() {
  return (
    <div>
      <div className="land-ack-container">
        <div className="land-ack-wrapper">
          <div className="land-ack-txt">
            <div className="land-body small-body">
              CodeJam is hosted on land which has long served as a site of
              meeting and exchange amongst Indigenous peoples, including the
              Haudenosaunee and Anishinabeg nations. CodeJam honours, recognizes
              and respects these nations as the traditional stewards of the
              lands and waters on which we meet.
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <div className="">
          &copy; CodeJam 2023 | Designed by{" "}
          <a
            className=""
            href="https://www.linkedin.com/in/jiaweiwong/"
            style={{ color: "white" }}
          >
            Jia Wei Wong
          </a>
        </div>
      </div>
    </div>
  );
}

export default LandAck;
