import React, { useEffect, useState } from "react";
import axios from "axios";

import "./Withdraw.scss";

const Withdraw = ({ apiUrl, currentUser, setErrorMessage, token }) => {
  const teamID = currentUser.teamID;

  const [teamName, setTeamName] = useState("");

  const [isConfirmationChecked, setIsConfirmationChecked] = useState(false);

  useEffect(() => {
    if (!currentUser.teamID) {
      window.location.href = "/dashboard/editTeam/create";
    } else {
      axios.get(apiUrl + "teams/" + currentUser.teamID).then((teamRes) => {
        console.log(teamRes);
        let teamResData = teamRes.data.data;

        setTeamName(teamResData.name);
      });
    }
  }, []);

  const handleCheckboxChange = (e) => {
    setIsConfirmationChecked(!isConfirmationChecked);
  };

  const onSubmit = () => {
    let requestData = {
      name: teamName,
      teamID: teamID,
    };

    const headers = {
      authorization: token,
    };

    axios
      .put(apiUrl + "teams/withdraw", requestData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.message.includes("Team withdrawn")) {
          window.location.href = "/dashboard/team/" + teamID;
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <>
      <div className="withdraw-page">
        <h1>Team Withdrawal</h1>
        <div className="withdraw-container">
          <div className="withdraw-input-box">
            <div className="text">
              <span className="required">*</span> Are you sure you want to
              withdraw from the judging round?
            </div>
            <div className="withdraw-checkbox-container">
              <div className="checkbox-input">
                <input
                  type="checkbox"
                  id="matroxCheckbox"
                  name="sponsoredChallenge"
                  className="withdraw-checkbox"
                  value={isConfirmationChecked}
                  checked={isConfirmationChecked}
                  onChange={(e) => {
                    handleCheckboxChange(e);
                  }}
                />

                <label
                  htmlFor="matroxCheckbox"
                  className="withdraw-checkbox-label"
                >
                  Yes
                </label>
              </div>
            </div>
          </div>

          {/* Submit button is not shown unless the required fields are filled */}
          {isConfirmationChecked && (
            <div className="button submit-button" onClick={onSubmit}>
              Submit
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Withdraw;
