import React from "react";
import "./ButtonCustom.scss";

function ButtonCustom(props) {
  return (
    <button className="button-container" onClick={props.onClick}>
      {props.title}
    </button>
  );
}
export default ButtonCustom;
