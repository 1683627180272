import React from "react";
import "./TabCard.scss";

function TabCard(props) {
  return (
    <div className="tabcard-container">
      <div className="text">
        <p className="line1">{props.line1}</p>
        <p className="line2">{props.line2}</p>
        <p className="line3">{props.line3}</p>
      </div>
    </div>
  );
}

export default TabCard;
