const PastEventsContent = [
  {
    title: "CodeJam 14",
    id: "codejam14",
    date: "November 2024",
    edition: "14th Edition | McGill University",
    participants: "___ Participants",
    prize: "$ ___ In Prizes",
    location: "In-Person Attendance",
    link: "",
    team: [
      {
        name: "",
        year: "",
        department: "",
        position: "",
        photo: require("../Resources/TeamImages/CodeJam14Team/jar.png"),
        linkedIn: "",
      },
    ],
  },
  {
    title: "CodeJam 13",
    id: "codejam13",
    date: "November 2023",
    edition: "13th Edition | McGill University",
    participants: "347 Participants",
    prize: "$10 460 In Prizes",
    location: "In-Person Attendance",
    link: "https://mcgill-codejam-13.devpost.com/",
    team: [
      {
        name: "Saagar Arya",
        year: "U4",
        department: "Software Engineering",
        position: "Co-President",
        photo: require("../Resources/TeamImages/Saagar.jpeg"),
        linkedIn: "https://www.linkedin.com/in/saagar-arya/",
      },
      {
        name: "Rui Du",
        year: "U5",
        department: "Mechanical Engineering (Software Minor)",
        position: "Co-President",
        photo: require("../Resources/TeamImages/rui.jpg"),
        linkedIn: "https://www.linkedin.com/in/ruidu1/",
      },
      {
        name: "Nafis Rahman",
        year: "U4",
        department: "Computer Engineering",
        position: "Website",
        photo: require("../Resources/TeamImages/nafis.jpg"),
        linkedIn: "https://www.linkedin.com/in/nafisrahman34/",
      },
      {
        name: "Ke Yan",
        year: "U4",
        department: "Software Engineering",
        position: "Sponsorship",
        photo: require("../Resources/TeamImages/ke.jpg"),
        linkedIn: "https://www.linkedin.com/in/ke-yan/",
      },
      {
        name: "Mahirul Islam",
        year: "U3",
        department: "Software Engineering",
        position: "Website",
        photo: require("../Resources/TeamImages/mahirul.jpeg"),
        linkedIn: "https://www.linkedin.com/in/mahirul-islam/",
      },
      {
        name: "Rei Kong",
        year: "U2",
        department: "Computer Science and Sociology",
        position: "Design",
        photo: require("../Resources/TeamImages/Rei Kong.jpg"),
        linkedIn: "https://www.linkedin.com/in/rei-kong/",
      },
      {
        name: "James Madden",
        year: "U1",
        department: "Computer Engineering",
        position: "Website",
        photo: require("../Resources/TeamImages/jamesMadden.jpeg"),
        linkedIn: "https://www.linkedin.com/in/jamesbmadden/",
      },
      {
        name: "Mohamad Junaid Arif",
        year: "U4",
        department: "Computer Engineering",
        position: "Sponsorship",
        photo: require("../Resources/TeamImages/Junaid.jpg"),
        linkedIn: "https://www.linkedin.com/in/junaidarif-mohammad/",
      },
      {
        name: "Riana Dutta",
        year: "U2",
        department: "Software Engineering",
        position: "Marketing",
        photo: require("../Resources/TeamImages/riana.jpeg"),
        linkedIn: "https://www.linkedin.com/in/riana-dutta/",
      },
      {
        name: "Vlad Arama",
        year: "U2",
        department: "Software Engineering",
        position: "Website",
        photo: require("../Resources/TeamImages/Vlad.JPG"),
        linkedIn: "https://www.linkedin.com/in/vladarama/",
      },
      {
        name: "Diego Castillo",
        year: "U3",
        department: "Computer Science",
        position: "Website",
        photo: require("../Resources/TeamImages/diego.jpeg"),
        linkedIn: "https://www.linkedin.com/in/diego-a-castillo/",
      },
      {
        name: "Lucy Mao",
        year: "U2",
        department: "Software Engineering",
        position: "Logistics",
        photo: require("../Resources/TeamImages/Lucy.JPG"),
        linkedIn: "https://www.linkedin.com/in/lucy-mao-lmprofile/",
      },
      {
        name: "Karen Dakkak",
        year: "U2",
        department: "Electrical Engineering",
        position: "Marketing",
        photo: require("../Resources/TeamImages/Karen.jpeg"),
        linkedIn: "https://www.linkedin.com/in/karen-dakkak-30515a237/",
      },
      {
        name: "Hareth Hmoud",
        year: "U4",
        department: "Computer Science",
        position: "Logistics",
        photo: require("../Resources/TeamImages/Hareth.jpg"),
        linkedIn: "https://www.linkedin.com/in/harethhmoud/",
      },
      {
        name: "Adam Al-Attrach",
        year: "U3",
        department: "Computer Science",
        position: "Website Lead",
        photo: require("../Resources/TeamImages/Adam.jpg"),
        linkedIn: "https://www.linkedin.com/in/adamalattrach/",
      },
      {
        name: "Sergio Najm",
        year: "U3",
        department: "Computer Science",
        position: "Website Lead",
        photo: require("../Resources/TeamImages/Sergio.png"),
        linkedIn: "https://www.linkedin.com/in/sergio-najm/",
      },
      {
        name: "Sam Mobed",
        year: "U3",
        department: "Computer Science",
        position: "Website",
        photo: require("../Resources/TeamImages/sam.png"),
        linkedIn: "https://www.linkedin.com/in/sam-mobed-541332212",
      },
      {
        name: "Sloane Holtby",
        year: "U2",
        department: "Software Engineering & Math",
        position: "Sponsorship",
        photo: require("../Resources/TeamImages/Sloane.jpg"),
        linkedIn: "https://www.linkedin.com/in/sloane-holtby-58b76422a/",
      },
      {
        name: "Behrad Rezaie",
        year: "U2",
        department: "Computer Engineering",
        position: "Sponsorship",
        photo: require("../Resources/TeamImages/behrad.jpeg"),
        linkedIn: "https://www.linkedin.com/in/behradrezaie/",
      },
      {
        name: "Aileena Xie",
        year: "U2",
        department: "Computer Science",
        position: "Website",
        photo: require("../Resources/TeamImages/leena.jpg"),
        linkedIn: "https://www.linkedin.com/in/aileena-xie/",
      },
    ],
  },
  {
    title: "CodeJam 12",
    id: "codejam12",
    date: "November 2022",
    edition: "12th Edition | McGill University",
    participants: "313 Participants",
    prize: "$9 195 in Prizes",
    location: "In-Person Attendance",
    link: "https://mcgill-codejam-12.devpost.com/?ref_feature=challenge&ref_medium=discover",
    team: [
      {
        name: "Saagar Arya",
        year: "U3",
        department: "Software Engineering",
        position: "CodeJam President",
        photo: require("../Resources/TeamImages/Saagar.jpeg"),
        linkedIn: "https://www.linkedin.com/in/saagar-arya/",
      },
      {
        name: "Jada Ke Thompson",
        year: "U4",
        department: "Mechanical Engineering (Software Minor)",
        position: "Finance and Logistics",
        photo: require("../Resources/TeamImages/jada.jpg"),
        linkedIn: "https://www.linkedin.com/in/jadakethompson/",
      },
      {
        name: "Catherine Van Gheluwe",
        year: "U4",
        department: "Software Engineering (AI Minor)",
        position: "Web Team Lead",
        photo: require("../Resources/TeamImages/catherine.jpg"),
        linkedIn: "https://www.linkedin.com/in/catherine-van-gheluwe/",
      },
      {
        name: "Himel Saha",
        year: "U4",
        department: "Computer Engineering",
        position: "Website and Logistics",
        photo: require("../Resources/TeamImages/himel.jpg"),
        linkedIn: "https://www.linkedin.com/in/himelsaha/",
      },
      {
        name: "Selena Jin",
        year: "U3",
        department: "Software Engineering",
        position: "Website and Logistics",
        photo: require("../Resources/TeamImages/selena.png"),
        linkedIn: "https://www.linkedin.com/in/selena-jin/",
      },
      {
        name: "Nafis Rahman",
        year: "U3",
        department: "Computer Engineering",
        position: "Website and Logistics",
        photo: require("../Resources/TeamImages/nafis.jpg"),
        linkedIn: "https://www.linkedin.com/in/nafisrahman34/",
      },
      {
        name: "Kevin Nam",
        year: "U3",
        department: "Electrical Engineering (Software Minor)",
        position: "Website and Logistics",
        photo: require("../Resources/TeamImages/kevin.jpg"),
        linkedIn: "https://www.linkedin.com/in/donggeunnam/",
      },
      {
        name: "Lily Xiong",
        year: "U2",
        department: "Stats and Comp Sci",
        position: "Website and Design",
        photo: require("../Resources/TeamImages/lily.jpg"),
        linkedIn: "https://www.linkedin.com/in/lily-xiong-205367230/",
      },
      {
        name: "Mahirul Islam",
        year: "U2",
        department: "Software Engineering",
        position: "Logistics",
        photo: require("../Resources/TeamImages/mahirul.jpeg"),
        linkedIn: "https://www.linkedin.com/in/mahirul-islam/",
      },
      {
        name: "Ke Yan",
        year: "U3",
        department: "Software Engineering",
        position: "Sponsorship and Marketing",
        photo: require("../Resources/TeamImages/ke.jpg"),
        linkedIn: "https://www.linkedin.com/in/ke-yan/",
      },
      {
        name: "Saadia Qureshi",
        year: "U3",
        department: "Software Engineering",
        position: "Marketing and Design",
        photo: require("../Resources/TeamImages/saadia.png"),
        linkedIn: "https://www.linkedin.com/in/saadia-qureshi-42657a197/",
      },
      {
        name: "Rui Du",
        year: "U4",
        department: "Mechanical Engineering (Software Minor)",
        position: "Sponsorship",
        photo: require("../Resources/TeamImages/rui.jpg"),
        linkedIn: "https://www.linkedin.com/in/ruidu1/",
      },
    ],
  },
  {
    title: "CodeJam 11",
    id: "codejam11",
    date: "February 2022",
    edition: "11th Edition | McGill University",
    participants: "323 Participants",
    prize: "$11 350 in Prizes",
    location: "Hybrid Attendance",
    link: "https://code-jam-xi.devpost.com/?ref_feature=challenge&ref_medium=discover",
  },
  {
    title: "Code.Jam(2020)",
    id: "codejam10",
    date: "November 2020",
    edition: "10th Edition | McGill University",
    participants: "457 Participants",
    prize: "$8 700 in Prizes",
    location: "Remote Attendance",
    link: "https://code-jam-2020.devpost.com/?ref_feature=challenge&ref_medium=discover",
  },
  {
    title: "Code.Jam(2019)",
    id: "codejam09",
    date: "November 2019",
    edition: "9th Edition | McGill University",
    participants: "300 Participants",
    prize: "$9 900 in Prizes",
    location: "In-person Attendance",
    link: "https://codejam2019.devpost.com/?ref_feature=challenge&ref_medium=discover",
  },
];

export { PastEventsContent };
