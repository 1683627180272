import React, { useState } from "react";
import propTypes from "prop-types";
import "./ForgotPassword.scss";
import "../../../Styles/variables.scss";
import errorImage from "../../../Resources/error.svg";
import { Link } from "react-router-dom";

export default function Login({ token, saveToken, apiUrl }) {
  const initialValues = { email: "" };
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  if (token) {
    return (window.location.href = "/dashboard/team");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedErrors = validate(values);
    setEmailError(updatedErrors.email);
    if (!updatedErrors.email) {
      setIsLoading(true); // Set loading to true
      const email = values.email;
      try {
        const response = await authenticate(email);
        if (response.status === 200) {
          setSuccessMessage("Check your email for the password");
          setEmailError(""); // Clear any existing errors
        } else if (response.status === 400) {
          setEmailError("User not found");
        } else {
          setEmailError(
            "An unexpected error occurred. Please try again later.",
          );
        }
      } catch (error) {
        setEmailError("An error occurred. Please try again.");
      } finally {
        setIsLoading(false); // Set loading to false regardless of outcome
      }
    }
  };

  async function authenticate(email) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    return await fetch(apiUrl + "users/forgot-password", requestOptions);
  }

  return (
    <div className="login-container">
      <div className="title-container">
        <h6>A temporary password will be sent to the email you provided</h6>
        <div className="email-container">
          <div className="email-header">Email</div>
          <div className="input-box">
            <input
              className={emailError ? "email-box-failure" : "email-box-success"}
              name="email"
              onChange={handleChange}
              placeholder="email@address.com"
              type="email"
              value={values.email}
              disabled={isLoading}
            />
          </div>
          <div className="error-messages-container">
            {isLoading ? (
              <div className="spinner"></div> // Replace text with spinner
            ) : (
              <>
                {emailError && (
                  <>
                    <img className="error-image" src={errorImage} />
                    <div className="error-messages">{emailError}</div>
                  </>
                )}
                {successMessage && (
                  <div className="success-message">{successMessage}</div>
                )}
              </>
            )}
          </div>
          <button
            className="login-button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            <b>Submit</b>
          </button>
        </div>
      </div>
    </div>
  );
}

const validate = (values) => {
  const errorlist = { email: "" };
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\\.,;:\s@\"]+\.)+[^<>()[\]\\.,;:\s@\"]{2,})$/i;
  if (!values.email) {
    errorlist.email = "Email is required.";
  } else if (!regex.test(values.email)) {
    errorlist.email = "Please enter a valid email.";
  } else {
    errorlist.email = "";
  }
  return errorlist;
};
