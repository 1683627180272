import React from "react";
import "../Styles/variables.scss";
import BlobRight from "../Resources/blob_right.svg";
import BlobLeft from "../Resources/blob_left.svg";

function Blob(props) {
  var left = false;
  var right = false;
  var width = 0;
  var height = 0;

  if (props.width === undefined) {
    // width = '1002.69vw';
    width = "50.69%";
  } else {
    width = props.width;
  }

  if (props.height === undefined) {
    // height = '788.09px';
    height = "90.98%";
  } else {
    height = props.height;
  }

  if (props.right === "true") {
    right = true;
    left = false;
  } else if (props.left === "true") {
    left = true;
    right = false;
  }

  return (
    <div
      style={
        right
          ? {
              position: "absolute",
              zIndex: -1,
              display: "flex",
              top: props.top,
              right: 0,
              height: height,
              width: width,
            }
          : {
              position: "absolute",
              zIndex: -1,
              top: props.top,
              left: 0,
              height: height,
              width: width,
            }
      }
    >
      {right && (
        <img
          src={BlobRight}
          alt="blob"
          style={{ height: "100%", width: "100%" }}
        />
      )}
      {left && (
        <img
          src={BlobLeft}
          alt="blob"
          style={{ height: "100%", width: "100%" }}
        />
      )}
    </div>
  );
}
export default Blob;
