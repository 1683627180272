import React from "react";
import "./Sponsorship.scss";
import "../../../Styles/variables.scss";

function Sponsorship() {
  return (
    <object
      className="sponsorship-pdf"
      type="application/pdf"
      data="/sponsorship-package.pdf"
    ></object>
  );
}

export default Sponsorship;
