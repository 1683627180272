import React, { useState } from "react";
import propTypes from "prop-types";
import "./Login.scss";
import "../../../Styles/variables.scss";
import showPasswordLogo from "../../../Resources/showPassword.svg";
import hidePasswordLogo from "../../../Resources/hidePassword.svg";
import errorImage from "../../../Resources/error.svg";
import { Link } from "react-router-dom";

export default function Login({ token, saveToken, apiUrl }) {
  const initialValues = { email: "", password: "" };
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [values, setValues] = useState(initialValues);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = (e) => {
    //update the values list with the values in email and password fields
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  if (token) {
    return (window.location.href = "/dashboard/team");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //validate the values entered in the email and password fields
    const updatedErrors = validate(values);
    //display errors if any are present
    setEmailError(updatedErrors.email);
    setPasswordError(updatedErrors.password);
    //proceed if no errors are present
    if (!updatedErrors.email && !updatedErrors.password) {
      //get the email and password values entered
      const email = values.email;
      const password = values.password;
      //api call to login and fetch token
      const response = await authenticate(email, password);
      if (response.status === 200) {
        //set PasswordError to nothing so no error is displayed
        setPasswordError("");
        //convert response body to string
        let body = await response.text();
        //split the string into arrays to separate the token received in the body
        const array = body.split(":");
        //retrieve the token by removing brackets
        let token = array[array.length - 1].substring(1, array[2].length - 2);
        // console.log(token);
        //set the token as a cookie and also set the token variable to be logged in
        saveToken(token);
        //navigate to the team page in the dashboard
        window.location.href = "/dashboard/team";
      } else {
        //display an error message if response status is not 200
        setPasswordError(
          "Wrong password or email. Please try again or click 'Forgot password?' to reset your password.",
        );
      }
    }
  };

  //this method is responsible for setting up the api call containing the email and password
  //it returns the response object
  async function authenticate(email, password) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("password", password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    var status = "";
    const output = await fetch(apiUrl + "users/login", requestOptions);
    const text = await output.status;
    console.log(text);
    return output;
  }

  return (
    <div className="login-container">
      <div className="title-container">
        <h4>Log into Code.Jam( ) </h4>
        <div className="email-container">
          <div className="email-header">Email</div>
          <div className="input-box">
            <input
              className={emailError ? "email-box-failure" : "email-box-success"}
              name="email"
              onChange={handleChange}
              placeholder="email@address.com"
              type="email"
              value={values.email}
            />
          </div>
          <div className="error-messages-container">
            {emailError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{emailError}</div>
          </div>
          <div className="password-header">
            <p>Password</p>
            <a className="forgot-password" href={"/forgot-password"}>
              Forgot Password?
            </a>
          </div>
          <div className="input-box">
            <input
              className={
                passwordError ? "password-box-failure" : "password-box-success"
              }
              id="password"
              name="password"
              onChange={handleChange}
              placeholder="Password"
              type={passwordVisible ? "text" : "password"}
              value={values.password}
            />
            {passwordVisible ? (
              <img
                className="input-box"
                src={showPasswordLogo}
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            ) : (
              <img
                className="input-box"
                src={hidePasswordLogo}
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            )}
          </div>
          <div className="error-messages-container">
            {passwordError ? (
              <img className="error-image" src={errorImage} />
            ) : (
              <></>
            )}
            <div className="error-messages">{passwordError}</div>
          </div>
          <button className="login-button" onClick={handleSubmit}>
            <b>Log in</b>
          </button>
          <div className="forgot-password-wrapper">
            Don't have an account?{" "}
            <Link to="/signup" className="forgot-password">
              Sign up here.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  saveToken: propTypes.func.isRequired,
};

//this method validates the email and password values and returns a list containing error messages
const validate = (values) => {
  const errorlist = { email: "", password: "" };
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!values.email) {
    errorlist.email = "Email is required.";
  } else if (!regex.test(values.email)) {
    errorlist.email = "Please enter a valid email.";
  } else {
    errorlist.email = "";
  }
  if (!values.password) {
    errorlist.password = "Password is required.";
  } else {
    errorlist.password = "";
  }
  return errorlist;
};
