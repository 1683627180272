import React from "react";
import "./Past.scss";
import "../../../Styles/variables.scss";
import PastEventsImage from "../../../Resources/PastEvents.svg";
import { PastEventsContent } from "../../../Data/PastEventsContent";
import Tabs from "../../../Global Components/Tabs/Tabs";

function Past() {
  return (
    <div className="home-container">
      {/* <div className="home-wrapper"> */}
      <div className="welcome-section">
        <div className="components">
          <img className="img" src={PastEventsImage} alt="pastEventsImage" />
          <div className="title">Past Events</div>
          <Tabs data={PastEventsContent} />
        </div>
      </div>
    </div>
  );
}

export default Past;
