import React, { useState, useEffect } from "react";
import axios from "axios";
import "../TeamBrowser.scss";
import filterArrow from "../../../../../src/Resources/Vector2.svg";
import boxfalse from "../../../../../src/Resources/tick-square.svg";
import boxtrue from "../../../../../src/Resources/ticktrue.svg";
import defaultpic from "../../../../../src/Resources/defaultpic.svg";
import DiscordLogo from "../../../../Resources/Icons/Discord_Icon.svg";
import { Link } from "react-router-dom";

const TeamTable = ({ apiUrl, searchInput, isFilteredByLooking }) => {
  const [teamMembersData, setTeamMembersData] = useState({});
  const [isTeamMembersLoaded, setIsTeamMembersLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(() => {
    // Fetch your team data from the database using Axios and set it in the 'teams' state.
    // Replace 'your-api-endpoint-here' with your actual API endpoint.
    axios
      .get(apiUrl + "teams/")
      .then((response) => {
        const teamsData = response.data.data;
        setTeams(teamsData);
        let filtered = [];
        let filteredTeamsLooking = [];
        // Filter teams on whether or not they are looking for members
        if (isFilteredByLooking) {
          filteredTeamsLooking = teamsData.filter(
            (team) => team.looking == true,
          );
          // Filter teams based on the search input
          filtered = filteredTeamsLooking.filter((team) =>
            team.name.toLowerCase().includes(searchInput.toLowerCase()),
          );
        } else {
          // Filter teams based on the search input
          filtered = teamsData.filter((team) =>
            team.name.toLowerCase().includes(searchInput.toLowerCase()),
          );
        }
        setFilteredTeams(filtered);
        setIsLoaded(true);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [apiUrl, searchInput, isFilteredByLooking]);

  useEffect(() => {
    // Fetch team members counts for each team when the 'teams' state changes
    const fetchMembersCounts = async () => {
      const promises = teams.map(async (team) => {
        const response = await axios.get(
          apiUrl + "teams/teamMembers/" + team.id,
        );
        return {
          teamId: team.id,
          count: response.data.data.length,
          members: response.data.data,
        };
      });

      // Wait for all promises to resolve
      const results = await Promise.all(promises);

      // Create an object with team member counts
      const membersData = {};
      const counts = {};
      results.forEach((result) => {
        counts[result.teamId] = result.count;
        membersData[result.teamId] = result.members;
      });

      // Set the state with team members counts
      setTeamMembers(counts);
      // Set team members
      setTeamMembersData(membersData);
      // Set isTeamMembersLoaded to true when team members data is fetched
      setIsTeamMembersLoaded(true);
    };

    fetchMembersCounts();
  }, [teams, apiUrl]);

  const renderMemberImages = (teamId) => {
    const teamMembersInfo = teamMembersData[teamId] || [];
    teamMembersInfo.map((member) => console.log(member));
    // Use an array of JSX elements directly in the return statement
    return teamMembersInfo.map((member, index) => (
      <img
        className="portraits"
        key={index}
        src={member.picture || defaultpic} // Replace with the actual property for the member's profile picture
      />
    ));
  };

  return isLoaded && isTeamMembersLoaded ? (
    <div>
      <table>
        <div className="table-style">
          <thead>
            <tr>
              <th
                style={{
                  paddingRight: "300px",
                }}
              >
                TEAM NAME
              </th>
              <th
                style={{
                  paddingRight: "100px",
                }}
              >
                LOOKING FOR MEMBERS
              </th>
              <th>TEAM MEMBERS</th>
            </tr>
          </thead>
        </div>
        {filteredTeams.length > 0 ? (
          <tbody>
            {filteredTeams.map((team) => (
              <tr key={team.name}>
                <Link to={"/dashboard/team/" + team.id}>
                  <td
                    className="name"
                    // this should be the longest
                    style={{
                      width: "90%",
                    }}
                  >
                    {team.name.length > 20
                      ? `${team.name.substring(0, 15)}...`
                      : team.name}
                  </td>
                </Link>
                <td className="looking">
                  {team.looking ? (
                    <img src={boxtrue}></img>
                  ) : (
                    <img src={boxfalse}></img>
                  )}
                </td>
                <td className="members">
                  {teamMembers[team.id]}
                  {renderMemberImages(team.id)}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <div>No teams yet</div>
        )}
      </table>
    </div>
  ) : (
    <div className="title">Loading...</div>
  );
};

export default TeamTable;
