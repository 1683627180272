import react, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

import DiscordLogo from "../../../Resources/Icons/Discord_Icon.svg";
import GithubLogo from "../../../Resources/Icons/Github_Icon.png";
import DevpostLogo from "../../../Resources/Icons/Devpost_Icon.svg";
import LinkedinLogo from "../../../Resources/Icons/Linkedin_Icon.png";
import InstagramLogo from "../../../Resources/Icons/Instagram_Icon.png";
import FacebookLogo from "../../../Resources/Icons/Facebook_Icon.png";
import TwitterLogo from "../../../Resources/Icons/Twitter_Icon.png";
import TiktokLogo from "../../../Resources/Icons/Tiktok_Icon.svg";

import CherryLogo from "../../../Resources/Icons/FoodGroups/Cherry.png";
import PeachLogo from "../../../Resources/Icons/FoodGroups/Peach.png";
import BlueberryLogo from "../../../Resources/Icons/FoodGroups/blueberry.png";
import StrawberryLogo from "../../../Resources/Icons/FoodGroups/strawberry.png";

import ArrowIcon from "../../../Resources/Icons/RArrow.svg";

import "./Profile.scss";

const Profile = ({ apiUrl, currentUser, setErrorMessage }) => {
  const id = useParams().id;

  const [user, setUser] = useState(null);
  const [team, setTeam] = useState("");
  const [copiedDiscord, setCopiedDiscord] = useState(false);

  if (id == null && user == null) {
    setUser(currentUser);
  }

  // if ID is not null, load the user
  if (id != null && user == null) {
    axios
      .get(apiUrl + `users/${id}`)
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        // show the error window
        setErrorMessage(error);
      });
  }

  // if the user has a team, load the info so the name can be shown
  if (user?.teamID && user?.teamID > 0) {
    axios
      .get(apiUrl + `teams/${user.teamID}/`)
      .then((response) => {
        setTeam(response.data.data.name);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  let foodGroupIcon = "";
  switch (user?.foodGroup) {
    case "cherry":
      foodGroupIcon = CherryLogo;
      break;
    case "peach":
      foodGroupIcon = PeachLogo;
      break;
    case "strawberry":
      foodGroupIcon = StrawberryLogo;
      break;
    case "blueberry":
      foodGroupIcon = BlueberryLogo;
      break;
  }

  if (user?.github && !user.github.includes("github.com/")) {
    user.github = "https://www.github.com/" + user.github;
    // github URLs by default don't include www., this is messing things up
    // } else if (user?.github && !user?.github.includes("https://www.")) {
    //   user.github = "https://www." + user.github;
  } else if (user?.github && !user?.github.includes("https://")) {
    user.github = "https://" + user.github;
  } else if (user?.github && user?.github.includes("?")) {
    user.github = user.github.split("?")[0];
  }

  if (user?.devpost && !user.devpost.includes("devpost.com/")) {
    user.devpost = "https://www.devpost.com/" + user.devpost;
    // same as github
    // } else if (user?.devpost && !user?.devpost.includes("https://www.")) {
    //   user.devpost = "https://www." + user.devpost;
  } else if (user?.devpost && !user?.devpost.includes("https://")) {
    user.devpost = "https://" + user.devpost;
  } else if (user?.devpost && user?.devpost.includes("?")) {
    user.devpost = user.devpost.split("?")[0];
  }

  if (user?.linkedin && !user.linkedin.includes("linkedin.com/")) {
    user.linkedin = "https://www.linkedin.com/in/" + user.linkedin;
  } else if (user?.linkedin && !user?.linkedin.includes("https://www.")) {
    user.linkedin = "https://www." + user.linkedin;
  } else if (user?.linkedin && !user?.linkedin.includes("https://")) {
    user.linkedin = "https://" + user.linkedin;
  } else if (user?.linkedin && user?.linkedin.includes("?")) {
    user.linkedin = user.linkedin.split("?")[0];
  }

  return (
    <div className="profile-page--wrapper">
      {user ? (
        <div className="profile-page">
          <h1 className="profile--title">
            {id == null ? "My Profile" : "Profile"}
          </h1>

          <article className="profile--main">
            <div className="profile--main-content">
              <div className="profile--header">
                <img className="profile--picture" src={user.picture} />
                {id == null ? (
                  <Link
                    to="/dashboard/editProfile"
                    style={{ textDecoration: "none" }}
                  >
                    <button>Edit Profile</button>
                  </Link>
                ) : null}
              </div>

              <div className="profile--details">
                <div className="profile--name">
                  <h1
                    style={
                      Math.max(user.firstName.length, user.lastName.length) > 20
                        ? {
                            fontSize: `${
                              (20 /
                                Math.max(
                                  user.firstName.length,
                                  user.lastName.length,
                                )) *
                              28
                            }px`,
                          }
                        : {}
                    }
                  >
                    {user.firstName} {user.lastName}
                  </h1>
                  <h2>{user.pronouns}</h2>
                </div>
                <h2 className="profile--team">
                  {team.length > 25 ? `${team.substring(0, 25)}...` : team}
                </h2>
                {user.foodGroup && (
                  <h3 className="profile--food-group">
                    Food Group:{" "}
                    <span className={user.foodGroup}>
                      <img src={foodGroupIcon} /> {user.foodGroup}
                    </span>
                  </h3>
                )}
                <p className="profile--bio">{user.bio}</p>

                <div className="profile--univeristy">
                  <h2 className="profile--university-school-label">School</h2>
                  <h2 className="profile--university-school">
                    {user.schoolOrCompany}
                  </h2>
                  <h2 className="profile--university-program-label">Program</h2>
                  <h2 className="profile--univeristy-program">
                    {user.programOrPosition}
                  </h2>
                </div>
              </div>
              <div className="profile--links">
                <ul>
                  {/* Only show each social link if the user has it */}
                  {user.discord ? (
                    <li>
                      <a
                        className="profile--social-link"
                        onClick={() => {
                          navigator.clipboard.writeText(user.discord);
                          setCopiedDiscord(true);
                        }}
                      >
                        <img className="icon" src={DiscordLogo} />
                        <span>{user.discord}</span>
                      </a>
                      {copiedDiscord && <span>Copied!</span>}
                    </li>
                  ) : null}

                  {user.github ? (
                    <li>
                      <a
                        className="profile--social-link"
                        href={user.github}
                        target="_blank"
                      >
                        <img className="icon" src={GithubLogo} />
                        <span>
                          {
                            user.github.replace(/\/\s*$/, "").split("/")[
                              user.github.replace(/\/\s*$/, "").split("/")
                                .length - 1
                            ]
                          }
                        </span>
                        <img className="arrow" src={ArrowIcon} />
                      </a>
                    </li>
                  ) : null}

                  {user.devpost ? (
                    <li>
                      <a
                        className="profile--social-link"
                        href={user.devpost}
                        target="_blank"
                      >
                        <img className="icon" src={DevpostLogo} />
                        <span>
                          {
                            user.devpost.replace(/\/\s*$/, "").split("/")[
                              user.devpost.replace(/\/\s*$/, "").split("/")
                                .length - 1
                            ]
                          }
                        </span>
                        <img className="arrow" src={ArrowIcon} />
                      </a>
                    </li>
                  ) : null}

                  {user.linkedin ? (
                    <li>
                      <a
                        className="profile--social-link"
                        href={user.linkedin}
                        target="_blank"
                      >
                        <img className="icon" src={LinkedinLogo} />
                        <span>
                          {
                            user.linkedin
                              .split("?")[0]
                              .replace(/\/\s*$/, "")
                              .split("/")[
                              user.linkedin
                                .split("?")[0]
                                .replace(/\/\s*$/, "")
                                .split("/").length - 1
                            ]
                          }
                        </span>
                        <img className="arrow" src={ArrowIcon} />
                      </a>
                    </li>
                  ) : null}

                  {user.instagram ? (
                    <li>
                      <a
                        className="profile--social-link"
                        href={"https://instagram.com/" + user.instagram}
                        target="_blank"
                      >
                        <img className="icon" src={InstagramLogo} />
                        <span>@{user.instagram}</span>
                        <img className="arrow" src={ArrowIcon} />
                      </a>
                    </li>
                  ) : null}

                  {user.twitter ? (
                    <li>
                      <a
                        className="profile--social-link"
                        href={"https://twitter.com/" + user.twitter}
                        target="_blank"
                      >
                        <img className="icon" src={TwitterLogo} />
                        <span>@{user.twitter}</span>
                        <img className="arrow" src={ArrowIcon} />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </article>
        </div>
      ) : null}
    </div>
  );
};

export default Profile;
