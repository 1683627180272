import React from "react";
import { useState } from "react";
import "./Accordion.scss";
import MinusSign from "../../Resources/minus.svg";
import PlusSign from "../../Resources/plus.svg";

function Accordion(props) {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected == i) return setSelected(null);
    return setSelected(i);
  };
  return (
    <div>
      {props.data.map((item, i) => {
        return (
          <div className="faq">
            <div className="faq-question" onClick={() => toggle(i)}>
              <div className="body">{item.question}</div>
              {selected === i ? (
                <img className="sign" src={MinusSign} alt="-" />
              ) : (
                <img className="sign" src={PlusSign} alt="+" />
              )}
            </div>
            <div className="faq-answer">
              <div className={selected !== i ? "closed" : "open"}>
                {item.answer}
              </div>
            </div>
          </div>
        );
      })}
      <div className="not-listed">
        <div>Have a question not listed here?</div>
        <div>
          Send us an email at{" "}
          <a
            href={"mailto:codejam@mcgilleus.ca"}
            target="_blank"
            rel="noopener noreferrer"
          >
            codejam@mcgilleus.ca
          </a>{" "}
          or reach out to us on our{" "}
          <a
            href="https://www.facebook.com/mcgillcodejam"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook Page
          </a>{" "}
          or{" "}
          <a
            href="https://www.instagram.com/mcgillcodejam"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>{" "}
          .
        </div>
      </div>
    </div>
  );
}

export default Accordion;
